import React, { Component } from "react";
import {
  Videoclub,
  CategoryDetail,
  Club,
  ClubDetail,
  // Live,
  // LiveProgramming,
  Login,
  Register,
  // Home,
  ShowDetail,
  MovieDetail,
  Account,
  ForgottenPassword,
  ResetPassword,
  Play,
  VerifyEmail,
  Subscribe
} from "views";
import { ScrollToTop } from "components";
import { AuthRoute, UnauthRoute } from "react-router-auth";
import { BrowserRouter as Router, Redirect, Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import auth from "./lib/Auth";
// import Novelties from "./views/Novelties";

global.auth = auth;

const REACT_APP_FRONTEND = process.env.REACT_APP_FRONTEND;
const REACT_APP_TITLE = process.env.REACT_APP_TITLE;
const REACT_APP_DESCRIPTION = process.env.REACT_APP_DESCRIPTION;
const REACT_APP_BASENAME = process.env.REACT_APP_BASENAME;

class App extends Component {
  defaultAuthRoute = "/videoclub";
  defaultUnauthRoute = "/login";

  state = {
    isAuthenticated: !!auth.authToken
  };

  routes = [
    {
      path: "/videoclub",
      component: Videoclub
    },
    {
      path: "/noticias",
      component: Club
    },
    {
      path: "/noticias/:id",
      component: ClubDetail
    },
    // {
    //   path: "/directo",
    //   component: Live
    // },
    // {
    //   path: "/directo/:channelId/programacion",
    //   component: LiveProgramming,
    //   exact: false
    // },
    // {
    //   path: "/novedades",
    //   component: Novelties
    // },
    {
      path: "/videoclub/serie/:showId",
      component: ShowDetail,
      exact: false
    },
    {
      path: "/videoclub/categoria/:categoryId",
      component: CategoryDetail,
      exact: false
    },
    {
      path: "/videoclub/video/:movieId",
      component: MovieDetail
    },
    {
      path: "/perfil",
      component: Account,
      exact: false
    },
    {
      isAuthRoute: false,
      path: "/forgotten-password",
      component: ForgottenPassword,
      exact: false
    },
    {
      isAuthRoute: false,
      path: "/reset-password",
      component: ResetPassword,
      exact: false
    },
    {
      isAuthRoute: false,
      path: "/verify-email",
      component: VerifyEmail,
      exact: true,
      redirect: false
    },
    // {
    //   isAuthRoute: false,
    //   path: "/",
    //   component: Home
    // },
    {
      isAuthRoute: false,
      path: "/login",
      component: Login
    },
    {
      path: "/nueva-suscripcion",
      component: Subscribe
    },
    {
      isAuthRoute: false,
      path: "/registro/:clubId",
      component: Register
    },
    {
      path: "/player/:movie_id",
      component: Play
    },
    {
      path: "/player/:movie_id_trailer/trailer",
      component: Play
    },
    {
      path: "/player/estamos-rodando/:inProduction_id",
      component: Play,
      exact: true
    },
    {
      path: "/player/:serie_id/:episode_id",
      component: Play,
      exact: true
    },
    {
      path: "/channel/:channel_id",
      component: Play
    },
    {
      isAuthRoute: true,
      path: "/commercial/:commercial_id",
      component: Play
    }
  ];

  componentDidMount() {
    window.addEventListener(
      "storage",
      () => {
        this.setState({
          isAuthenticated: !!auth.authToken
        });
      },
      false
    );

    auth.init(isAuthenticated => {
      this.setState({
        isAuthenticated
      });
    });

    if (localStorage.getItem("strapiKey")) {
      localStorage.setItem("stripeKey", localStorage.getItem("strapiKey"))
    }
  }

  render() {
    const { isAuthenticated } = this.state;

    return (
      <React.Fragment>

        <Helmet>
          <meta charSet="utf-8"/>
          <title>{REACT_APP_TITLE}</title>
          <meta name="description" content={REACT_APP_DESCRIPTION}/>
          <link rel="shortcut icon" href={require("./env/img/" + (REACT_APP_FRONTEND || "brand") + "/favicon.ico")}/>
          <meta property="og:image" content={require("./env/img/" + (REACT_APP_FRONTEND || "brand") + "/favicon.ico")}/>
        </Helmet>

        <Router {...(REACT_APP_BASENAME ? { basename: REACT_APP_BASENAME } : {})}>
          <ScrollToTop>
            <div className="site-container">
              <Switch>
                {this.routes.map(
                  (
                    {
                      isAuthRoute = true,
                      path,
                      component,
                      exact = true,
                      redirect = true
                    },
                    index
                  ) => {
                    return isAuthRoute ? (
                      <AuthRoute
                        key={index}
                        exact={exact}
                        strict
                        path={path}
                        component={component}
                        redirectTo={this.defaultUnauthRoute}
                        authenticated={isAuthenticated}
                      />
                    ) : (
                      <UnauthRoute
                        key={index}
                        exact
                        strict
                        path={path}
                        component={component}
                        redirectTo={this.defaultAuthRoute}
                        authenticated={redirect ? isAuthenticated : false}
                      />
                    );
                  }
                )}
                <Redirect to={isAuthenticated ? "/videoclub" : "/login"}/>
              </Switch>
            </div>
          </ScrollToTop>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
