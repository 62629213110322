const BASE_API_URL = process.env.REACT_APP_API_BASEURL;

function RequestError({ status }) {
  this.message = `Status Error ${status}`;
  this.status = status;
}

function request(
  url,
  options = { method: "GET", redirectOnUnauthorized: true }
) {
  const token = localStorage.getItem("authToken");
  return cancellablePromise(
    fetch(BASE_API_URL + url, {
      headers: {
        "Accept-Language": "application/json",
        authorization: token ? `Bearer ${token}` : undefined,
        "Content-Type": "application/json"
      },
      ...options
    })
      .then(res => {
        switch (res.status) {
          case 200:
            return res.json();
          case 401:
            if (options.redirectOnUnauthorized) {
              window.location = "/login";
            }
            localStorage.removeItem("authToken");
            return res.json().then(body => ({
              error: new RequestError({
                status: res.status
              }),
              body
            }));
          default:
            return res.json().then(body => ({
              error: new RequestError({
                status: res.status
              }),
              body
            }));
        }
      })
      .catch(error => {
        return error;
      })
  );
}

const cancellablePromise = promise => {
  let hasCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(
      val => (hasCanceled ? reject({ isCanceled: true }) : resolve(val)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
};

export default request;
