import React, { Component } from "react";
import { Page } from "components";
import { CTA } from "ui";
import request from "lib/request";
import Swal from 'sweetalert2'
import VideoCarousel from "../components/VideoCarousel";
import VideoPreview from "../components/VideoPreview";
import { loadStripe } from '@stripe/stripe-js';
import getCurrency from "../lib/getCurrency";

const stripeKey = localStorage.getItem("stripeKey");
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
  stripeAccount: stripeKey,
});



class MovieDetail extends Component {
  state = {
    movie: {}
  };


  componentDidMount() {
    this.getMovieDetails(this.props.match.params.movieId);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.match.params.movieId !== prevProps.match.params.movieId) {
      this.getMovieDetails(this.props.match.params.movieId)
    }
  }

  componentWillUnmount() {
    if (this.getMovieDetailsRequest) this.getMovieDetailsRequest.cancel();
  }

  getMovieDetails = movieId => {
    this.getMovieDetailsRequest = request(`products/${movieId}`);
    this.getMovieDetailsRequest.promise.then(movie => this.setState({ movie }));
  };

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }


  // getHtmlOnPlay = (product) => {
  //   console.log(product)
  //   const { rentalProduct, eventEnd, eventStart, video } = product;
  //   const { subscriptionBlocked } = video;
  //   let text = '<p style="padding-bottom: 10px">No puedes disfrutar de este contenido porque tu Suscripción ha Caducado. ¡Suscribete ahora para ver todos nuestros contenidos si limites!</p> '
  //
  //   if (rentalProduct) {
  //     if (eventEnd && eventStart) {
  //       text = text + '<p>O si lo prefieres, consigue acceso a este evento por solo ' + getCurrency(rentalProduct) + ', disfrútalo durante el tiempo que dure el evento.</p>'
  //     } else {
  //       text = text + '<p>O si lo prefieres, alquila este documental por solo ' + getCurrency(rentalProduct) + ', disfrútalo durante 3 días.</p>'
  //     }
  //     if (rentalProduct.subscriptionNeeded && subscriptionBlocked) {
  //       text = text + '<p style="padding-top: 10px">Se requiere una subscripción para tener acceso a este contendio.</p>'
  //     }
  //   }
  //   return text;
  // };
  needSubscriptionAndRental = () => {
    Swal.fire({
      icon: "warning",
      title: '!Suscribete ya!',
      html: '<p style="padding-bottom: 10px">Para disfrutar de este contenido necesita una previa subscripción antes de poder adquirir el video o acceso al evento!</p>',
      type: 'warning',
      position: 'center',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Suscribirme',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.value) {
        this.props.history.push('/perfil/estado-suscripcion')
      }
    })
  };
  rentalVideo = async (product) => {
    const { id, title, price } = product;
    const stripe = await stripePromise;

    const requestMethods = request(`subscriptions/users/cards`);
    requestMethods.promise.then(data => {
      const cards = data.cards || [];
      const options = {};
      cards.forEach(card => {
        options[card.cardId] = "XXXX XXXX XXXX " + card.last4.toString()
      });
      const hasMethods = Object.keys(options).length > 0;
      Swal.fire({
        icon: "question",
        title: 'Alquilar: ' + this.capitalizeFirstLetter(title.toLowerCase()),
        input: hasMethods ? 'select' : null,
        footer: "Alquila " + title.toUpperCase() + " por " + getCurrency(price) + " durante 3 días. Tras finalizar el pago, el acceso a este video podría demorar unos minutos.",
        inputOptions: options,
        inputPlaceholder: 'Selecciona tu método de pago',
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: hasMethods ? 'Alquilar' : "Insertar método de pago",
        cancelButtonText: "Cancelar",
        preConfirm: (payMethodId) => {
          if (payMethodId === "null") {
            return ({ error: true })
          }
          if (hasMethods) {
            const rent = request(
              `subscriptions/rent`,
              {
                method: "POST",
                body: JSON.stringify({
                  paymentMethodId: payMethodId,
                  productId: id
                })
              }
            );
            return rent.promise
          }
          return { callback: () => this.props.history.push('/perfil/payment-methods') }
        }
      }).then((data) => {
        if (!data.value) {
          return;
        }
        if (data.value.error) {
          return Swal.fire('Error!',
            'Ha ocurrido un error al alquilar el video, pruebe más tarde',
            'error')
        }
        if (data.value.callback) {
          return data.value.callback();
        }
        const secret = data.value.piSecret;

        stripe.confirmCardPayment(secret).then((result) => {
          if (result.error) {
            return Swal.fire('Error!',
              'Ha ocurrido un error con el pago del alquiler, intente usar otra tarjeta',
              'error')
          } else {
            return Swal.fire({
              title: 'Alquilado!',
              text: 'Tu video ha sido alquilado. Estamos procesando el pago. Recibirá un email cuando el video esté disponible. El alquiler es de 3 días',
              icon: 'success'
            }).then(() => {
              window.location.replace("");
            })
          }
        })
          .catch(() => {
            return Swal.fire('Error!',
              'Ha ocurrido un error con el pago del alquiler, intente usar otra tarjeta',
              'error')
          });
      })
    });
  };
  rentalEvent = async (product) => {
    const { id, title, rentalProduct } = product;
    const stripe = await stripePromise;

    const requestMethods = request(`/subscriptions/users/cards`);
    requestMethods.promise.then(data => {
      const cards = data.cards || [];
      const options = {};
      cards.forEach(card => {
        options[card.cardId] = "XXXX XXXX XXXX " + card.last4.toString()
      });
      const hasMethods = Object.keys(options).length > 0;
      Swal.fire({
        icon: "question",
        title: 'Acceder a: ' + this.capitalizeFirstLetter(title.toLowerCase()),
        input: hasMethods ? 'select' : null,
        footer: "Consigue acceso a " + title.toUpperCase() + " por " + getCurrency(rentalProduct) + " durante el tiempo que dure el evento. Tras finalizar el pago, el acceso a este evento podría demorar unos minutos.",
        inputOptions: options,
        inputPlaceholder: 'Selecciona tu método de pago',
        showCancelButton: true,
        showCloseButton: true,
        showLoaderOnConfirm: true,
        confirmButtonText: hasMethods ? 'Conseguir acceso' : "Insertar método de pago",
        cancelButtonText: "Cancelar",
        preConfirm: (payMethodId) => {
          if (payMethodId === "null") {
            return ({ error: true })
          }
          if (hasMethods) {
            const rent = request(
              `subscriptions/rent`,
              {
                method: "POST",
                body: JSON.stringify({
                  paymentMethodId: payMethodId,
                  productId: id
                })
              }
            );
            return rent.promise
          }
          return { callback: () => this.props.history.push('/perfil/payment-methods') }
        }
      }).then((data) => {
        if (!data.value) {
          return;
        }
        if (data.value.error) {
          return Swal.fire('Error!',
            'Ha ocurrido un error al conseguir tu acceso al evento, pruebe más tarde',
            'error')
        }
        if (data.value.callback) {
          return data.value.callback();
        }
        const secret = data.value.secret;

        stripe.confirmCardPayment(secret).then((result) => {
          if (result.error) {
            return Swal.fire('Error!',
              'Ha ocurrido un error con el pago, intente usar otra tarjeta',
              'error')
          } else {
            return Swal.fire({
              title: 'Ya tienes acceso!',
              text: 'Has conseguido tu acceso al evento. Estamos procesando el pago. Recibirá un email cuando el acceso esté disponible. El acceso será válido hasta el finál del evento',
              icon: 'success',
            }).then(() => {
              window.location.replace("");
            })
          }
        });
      })
    });
  };
  needSubscriptionNoRental = () => {
    Swal.fire({
      icon: "warning",
      title: '!Suscribete ya!',
      html: '<p style="padding-bottom: 10px">No puedes disfrutar de este contenido porque tu Suscripción ha Caducado. ¡Suscribete ahora para ver todos nuestros contenidos si limites!</p>',
      type: 'warning',
      position: 'center',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: 'Suscribirme',
      showCancelButton: true,
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.value) {
        this.props.history.push('/perfil/estado-suscripcion')
      }
    })
  };

  rentalProduct = (product) => {
    const { eventEnd, eventStart } = product;
    if (eventStart && eventEnd) {
      this.rentalEvent(product)
    } else {
      this.rentalVideo(product)
    }
  }

  onPlayButtonClick = (product) => {
    const { price, video = {}, id } = product;
    const { subscriptionBlocked, hlsUrl } = video;
    if (hlsUrl) {
      this.props.history.push(`/player/${id}`);
    } else if (subscriptionBlocked) {
      if (price) {
        return this.rentalProduct(product)
        // const { ammount, planId } = price;
        // if (subscriptionNeeded) {
        //   if (canRent) {
        //     return this.rentalProduct(product)
        //   } else {
        //     return this.needSubscriptionAndRental(product)
        //   }
        // } else {
        //   return this.rentalProduct(product)
        // }
      } else {
        return this.needSubscriptionNoRental(product)
      }
    }
  }

  renderCarouselItem = video => (
    <VideoPreview
      title={video.title}
      image={video.thumbnail ? video.thumbnail.url : undefined}
      onPlayButtonClick={() => this.playVideo(video)}
    />
  );

  playVideo = video => {
    if (video.series) {
      this.props.history.push(`/videoclub/serie/${video.id}`);
    } else {
      this.props.history.push(`/videoclub/video/${video.id}`);
    }
  };

  render() {
    const { video, title, description, webImage, speciesList, producer, relatedProducts, rentalProduct, eventStart, eventEnd } = this.state.movie;

    let textPrincipalButton = "Reproducir"
    if (video && video.subscriptionBlocked) {
      if (rentalProduct) {
        if (eventStart && eventEnd) {
          textPrincipalButton = "Acceder por " + getCurrency(rentalProduct)
        } else {
          textPrincipalButton = "Alquilar por " + getCurrency(rentalProduct)
        }
      }
    }


    return (
      <Page
        className="show-detail"
        containerStyles={{ margin: "unset", width: "100%" }}
      >
        <div
          className="flex"
          style={heroContainerStyles(
            `url("${webImage ? webImage.url : undefined}")`
          )}
        >
          <div style={{ alignSelf: "flex-end", marginBottom: 20, padding: 10 }}>
            <h1 style={{ fontWeight: 500 }}>{title}</h1>
            <div
              style={{
                display: "flex",
                margin: "auto",
                maxWidth: 500,
                justifyContent: "space-between"
              }}
            >
              {(video) &&
              <CTA title={textPrincipalButton}
                   onClick={() =>
                     this.onPlayButtonClick(this.state.movie)
                   }
                   style={{ marginRight: 10 }}
              />
              }
              <CTA title="Ver Trailer" onClick={() => this.onPlayButtonClick(this.state.movie)}/>
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <div className="show-info-container">
              <p
                style={{ lineHeight: 1.5 }}
                dangerouslySetInnerHTML={{ __html: description }}
              />
            </div>
          </div>
        </div>
        {speciesList && <div className="flex">
          <div className="w-full">
            <div className="show-info-container flex" style={{ flexWrap: "wrap", paddingBottom: 0 }}>
              <h2
                style={{
                  fontSize: "20pt",
                  fontWeight: 400,
                  textAlign: "left"
                }}
              >
                Objetivos:
              </h2>
            </div>
            <div className="show-info-container flex" style={{ flexWrap: "wrap", paddingBottom: 0, marginBottom: 20 }}>
              {speciesList.map((specie, key) => <CTA className="bg-primary" onClick={event => event.preventDefault()}
                                                     key={key} style={{ margin: "5px 10px 5px 0" }}
                                                     title={specie.name}/>)}
            </div>
          </div>
        </div>}

        <div className="flex">
          <div className="w-full">
            <div className="show-info-container flex"
                 style={{ flexWrap: "wrap", paddingBottom: 0, marginBottom: 30 }}>
              <h3
                style={{
                  fontSize: "20pt",
                  fontWeight: 400,
                  textAlign: "left",
                  marginRight: 10
                }}
              >
                Entrenador:
              </h3>
              <div style={{
                fontSize: "20pt",
                fontWeight: 600,
                textAlign: "left",
              }}>
                {producer}
              </div>
            </div>
          </div>
        </div>
        {
          (relatedProducts && (relatedProducts.productList || []).length > 0) &&
          <div className="video-carousels-wrapper">
            <VideoCarousel
              renderTitle={() => "Videos relacionados"}
              renderViewMore={() => null}
              items={relatedProducts.productList}
              renderItem={this.renderCarouselItem}
              onTap={this.playVideo}
              onClick={this.playVideo}
            />
          </div>
        }


      </Page>
    );
  }
}

const
  heroContainerStyles = image => ({
    paddingTop: 100,
    height: "100%",
    justifyContent: "center",
    backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.8)), ${image}`,
    backgroundSize: "cover",
    backgroundPosition: "50% 0"
  });

export default MovieDetail;
