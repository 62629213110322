import React, { Component } from "react";
import { Page, VideoCarousel, VideoPreview } from "components";
import request from "lib/request";

class Novelties extends Component {
  state = {
    partners: []
  };

  componentDidMount() {
    this.getNovelties = request("club/partners");
    this.getNovelties.promise.then(({ partnerList }) =>
      this.setState({ partners: partnerList })
    );
  }

  componentWillUnmount() {
    if (this.getNovelties) this.getNovelties.cancel();
  }

  playVideo(videoId) {
    this.props.history.push(`/commercial/${videoId}`);
  }

  renderCarouselItem = ({ id, videoImage, title }) => (
    <VideoPreview
      image={videoImage.url}
      title={title}
      onPlayButtonClick={() => this.playVideo(id)}
    />
  );

  render() {
    const { partners } = this.state;
    return (
      <Page className="page-container private-page videoclub-page">
        <div className="video-carousels-wrapper">
          <h2 style={{ textAlign: "center" }} className="">
            Novedades
          </h2>
          {partners.map(({ name, image, commercialList }, index) => {
            return (
              <VideoCarousel
                renderFirstItem={() => (
                  <VideoPreview
                    image={image.url}
                    showNeverPlayButton={true}
                    imageMode="contain"
                    gradient={false}
                  />
                )}
                key={index}
                carouselTitle={name}
                items={commercialList}
                renderItem={this.renderCarouselItem}
              />
            );
          })}
        </div>
      </Page>
    );
  }
}

export default Novelties;
