import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TimeFor from "./TimeFor";
import moment from "moment";

const getHeight = ({ fullHeight }) => (fullHeight ? "100%;" : "unset");

const PreviewContainer = styled.div`
  position: relative;
  height: ${getHeight};

  :hover::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    box-shadow: inset 0 0 0 1px;
    box-shadow: inset 0 0 0 0.0625rem;
    z-index: 11;
    display: ${({ showNeverPlayButton }) =>
  showNeverPlayButton ? "none" : "block"};
  }

  :hover .overlay-container {
    display: ${({ showNeverPlayButton }) =>
  showNeverPlayButton ? "none" : "block"};
  }
`;

const ImageContainer = styled.div`
  height: ${getHeight};
  background-color: ${({ imageContainerWithBackground }) =>
  imageContainerWithBackground ? "#242424" : "transparent"};
  background-repeat: no-repeat;
  background-position: 50% 50%;
`;

const Image = styled.div`  
  opacity: 1;
  image-rendering: -webkit-optimize-contrast;
  background: no-repeat 50% 50%;  
  background-image: ${({ gradient }) =>
  gradient && "linear-gradient(transparent, rgba(0, 0, 0, 1)), "} url("${({
                                                                            image
                                                                          }) => image}");
  background-size: ${({ imageMode }) => imageMode};
  padding-bottom: ${({ paddingBottom }) => paddingBottom};  
  height: ${getHeight};
`;

const OverlayContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: ${({ showAlwaysPlayButton }) =>
  showAlwaysPlayButton ? "block" : "none"};
`;

const LinkContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 15;
  transform: translate(-50%, -50%);
`;

const iconSizes = {
  "2x": "3.125rem",
  "3x": "5rem",
  "4x": "6rem",
  "5x": "7.75rem"
};

const getIconSize = ({ iconSize }) => iconSizes[iconSize];

const Link = styled.button`
  width: ${getIconSize};
  height: ${getIconSize};
  display: block;
  position: relative;
  border-radius: 50%;
  @media (max-width: 992px) {
    display: ${({ showAlwaysPlayButton }) =>
  showAlwaysPlayButton ? "block" : "none"};
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  width: 42%;
  height: 42%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-40%, -50%);
`;

const Title = styled.p`
  margin-top: 5px;
  white-space: normal;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
  text-overflow: ellipsis;
  text-overflow: -o-ellipsis-lastline;
  line-height: 1.25em;
  max-height: 2.5em;
  font-weight: 200;

  @media (max-width: 576px) {
    font-size: 0.75rem;
  }
`;

const ImageTitle = styled(Title)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 20pt;
  font-weight: 500;
`;

const ImageInfo = styled(Title)`
  background-color: #d4433382;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 14pt;
  font-weight: 500;
`;

const VideoPreview = ({
                        liveSchedule,
                        image = "https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
                        imageMode = "cover",
                        gradient = true,
                        imageContainerWithBackground = true,
                        fullHeight = false,
                        onPlayButtonClick = () => {
                        },
                        showAlwaysPlayButton = false,
                        showNeverPlayButton = false,
                        showTitleOnImage = false,
                        iconSize = "2x",
                        title = "",
                        paddingBottom = "50.25%",
                        video = {}
                      }) => {


  return (
    <React.Fragment>
      <PreviewContainer
        className="shadow-primary-hover"
        fullHeight={fullHeight}
        showNeverPlayButton={showNeverPlayButton}
      >
        <ImageContainer {...fullHeight} {...imageContainerWithBackground}>
          <Image
            role="img"
            tabIndex="-1"
            {...{ gradient, image, imageMode, fullHeight, paddingBottom }}
          />
          {showTitleOnImage && <ImageTitle>{title}</ImageTitle>}
          {liveSchedule && <ImageInfo>
            {moment().isBefore(liveSchedule.end) ?
              <TimeFor data={liveSchedule.start} textAfterData={"En Directo"}/> :
              "Finalizado"
            }
          </ImageInfo>}
        </ImageContainer>
        <OverlayContainer
          showAlwaysPlayButton={showAlwaysPlayButton}
          className="overlay-container"
        >
          <LinkContainer>
            <Link
              className="bg-primary-hover"
              iconSize={iconSize}
              onClick={event => onPlayButtonClick({ event, video })}
              showAlwaysPlayButton={showAlwaysPlayButton}
            >
              <StyledFontAwesomeIcon icon="play" size={iconSize}/>
            </Link>
          </LinkContainer>
        </OverlayContainer>
      </PreviewContainer>
      {title && !showTitleOnImage ? <Title>{title}</Title> : null}
    </React.Fragment>
  );
};

export default VideoPreview;
