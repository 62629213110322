import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "components";
import request from "lib/request";

class ForgottenPassword extends Component {
  state = {
    email: "",
    submitResponse: null,
    sendingResetPassword: false
  };

  submitPasswordReset = event => {
    event.preventDefault();

    this.setState({
      sendingResetPassword: true
    });

    this.resetPasswordRequest = request(
      `forgotten-password?email=${this.state.email}`
    );
    this.resetPasswordRequest.promise.then(response => {
      this.setState({
        sendingResetPassword: false
      });

      if (!response || !response.error) {
        this.setState({
          submitResponse: {
            type: "info",
            message:
              "En breve recibirá un email con un código para restablecer su contraseña."
          }
        });
      } else {
        let message = "";
        switch (response.error.status) {
          case 401:
            message = "Email no reconocido";
            break;
          default:
            message = `Error desconocido ${response.error.status}`;
        }

        this.setState({
          submitResponse: {
            type: "error",
            message
          }
        });
      }
    });
  };

  componentWillUnmount() {
    if (this.resetPasswordRequest) {
      this.resetPasswordRequest.cancel();
    }
  }

  handleInputChange(self, event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    self.setState({
      [name]: value,
      submitResponse: null
    });
  }

  render() {
    return (
      <Page
        showMenus={false}
        showProfile={false}
        className="page-container public-page recover-password-page"
      >
        <div className="page-content">
          <h4 className="mb-4">Recupera tu contraseña</h4>
          <form
            onSubmit={this.submitPasswordReset}
            className="flex flex-wrap register-step register-step-2 mb-8"
            autoComplete="on"
          >
            <div className="w-1 mr-auto">
              <label className="block mb-2 leading-tight text-xs">Email</label>
              <input
                type="email"
                name="email"
                className="mb-4 leading-normal p-2 rounded"
                value={this.state.email}
                onChange={event => this.handleInputChange(this, event)}
              />
              <div className="flex items-center justify-between mt-2">
                <button
                  className="uppercase py-3 font-medium bg-primary-hover font-bold py-2 px-4 rounded text-sm"
                  type="submit"
                >
                  {this.state.sendingResetPassword
                    ? "Enviando..."
                    : "Restablecer"}
                </button>
              </div>
            </div>
          </form>
          <div className="flex flex-wrap mb-8 register-step">
            {this.state.submitResponse && this.state.submitResponse.message ? (
              <div
                className={`w-1 w-1 mr-auto login-response login-response-type-${
                  this.state.submitResponse.type
                } text-center`}
              >
                <p>{this.state.submitResponse.message}</p>
                {this.state.submitResponse.type === "info" && (
                  <p style={{ marginTop: 10 }}>
                    <Link
                      style={{ color: "white" }}
                      to={"/reset-password?email=" + this.state.email}
                    >
                      Ya tengo el código
                    </Link>
                  </p>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Page>
    );
  }
}

export default ForgottenPassword;
