import React from "react";
import { limitText } from "lib/utils";
import { CTA } from "ui";

const Contest = ({
  title,
  image = "https://images.unsplash.com/photo-1521651201144-634f700b36ef?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=11e83d71b84aec7e15917901c244d434&auto=format&fit=crop&w=1950&q=80",
  description,
  screen,
  url = "#",
  noCTA = false
}) => {
  return (
    <div
      className={` ${
        screen ? screen : "max-w-sm"
      } rounded overflow-hidden shadow-lg ml-5 mb-6 mr-5`}
      style={{ backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.8)), url("${image}")`, backgroundSize: "cover", fontWeight: 500 }}
    >
      <div className="px-6 py-4 mt-32">
        <div className="font-bold text-xl mb-2">{title}</div> 
        <p
          className="text-white text-base"
          dangerouslySetInnerHTML={{ __html: limitText(description) }}
        />
        {noCTA ? null : (
          <CTA
            title="Ver más"
            style={{ width: "150px" }}
            url={url}
            className="mt-5"
          />
        )}
      </div>
    </div>
  );
};

export default Contest;
