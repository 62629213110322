import React from "react";
import { NavLink } from "react-router-dom";
import MaterialIcon from "material-icons-react";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import Search from "../views/Search";

const REACT_APP_FRONTEND=process.env.REACT_APP_FRONTEND;

const ListItem = styled.li`
  margin-left: 0 !important;

  @media (max-width: 900px) {
    a {
      font-size: 13px;
      text-decoration: none;
      color: white;
    }

    a.active {
      font-weight: 500;
    }
  }
`;

const Brand = styled.span`
  @media (max-width: 900px) {
    font-size: 20px;
  }
`;

const Image = styled.img`
  max-width: 250px;
  max-height: 50px;

  @media (min-width: 900px) {
    padding-left: 30px;
    padding-right: 50px;
  }
`;

class SiteNavigation extends React.Component {
  state = {
    showSearchView: false
  };

  onSearchClick = () => {
    this.setState({ showSearchView: true });
  };

  onCloseSearchButtonClick = () => {
    this.setState({ showSearchView: false });
  };

  render() {
    const { showSearchView } = this.state;
    return (
      <React.Fragment>
        {showSearchView && (
          <Search onCloseButtonClick={this.onCloseSearchButtonClick} />
        )}
        <MediaQuery query="(min-width: 900px)">
          {this.renderDesktopVersion()}
        </MediaQuery>
        <MediaQuery query="(max-width: 900px)">
          {this.renderMobileVersion()}
        </MediaQuery>
      </React.Fragment>
    );
  }


  renderDesktopVersion = () => {
    const { className, showMenus, menus, rightButtons = () => {} } = this.props;
    return (
      <header className={`site-header ${className}`}>
        <NavLink to="/">
          <Image src={require("../env/img/" + (REACT_APP_FRONTEND || "brand") + "/logo.png")} />
        </NavLink>
        <ul className="site-navigation-left flex">
          {showMenus &&
            menus.map(({ url = "#", title }, index) => (
              <ListItem key={index}>
                <NavLink to={url} activeClassName="active" strict>
                  {title}
                </NavLink>
              </ListItem>
            ))}
        </ul>
        <ul className="site-navigation-right">
          {rightButtons()}
          {this.renderSearch()}
          {this.renderProfile()}
        </ul>
      </header>
    );
  };

  renderMobileVersion = () => {
    const { showSearch, menus } = this.props;
    return (
      <header
        className="site-header pr-4 pl-4 flex-col pt-2 md:pt-6"
        style={{ height: "auto" }}
      >
        <div className="flex justify-around items-center">
          {this.renderProfile(false)}
          <Brand className="brand">
            <Image src={require("../env/img/" + (REACT_APP_FRONTEND || "brand") + "/logo.png")} />
          </Brand>
          {showSearch ? (
            this.renderSearch(false)
          ) : (
            <span style={{ width: 28, height: 32 }} />
          )}
        </div>
        <div style={{ width: "100%", marginTop: 5, marginBottom: 10 }}>
          <ul className="flex list-reset justify-around">
            {menus.map(({ url = "#", title }, index) => (
              <ListItem key={index}>
                <NavLink to={url} activeClassName="active" strict>
                  {title}
                </NavLink>
              </ListItem>
            ))}
          </ul>
        </div>
      </header>
    );
  };

  renderProfile = (isDesktop = true) => {
    const { showProfile } = this.props;
    return (
      showProfile &&
      (isDesktop ? (
        <ListItem>
          <NavLink to="/perfil" activeClassName="active" strict>
            <MaterialIcon icon="person" color="white" size="28" />
          </NavLink>
        </ListItem>
      ) : (
        <NavLink to="/perfil" activeClassName="active" strict>
          <MaterialIcon icon="person" color="white" size="28" />
        </NavLink>
      ))
    );
  };

  renderSearch = (isDesktop = true) => {
    const { showSearch } = this.props;
    return (
      showSearch &&
      (isDesktop ? (
        <ListItem onClick={this.onSearchClick}>
          <MaterialIcon icon="search" color="white" size="28" />
        </ListItem>
      ) : (
        <MaterialIcon icon="search" color="white" size="28" />
      ))
    );
  };
}

export default SiteNavigation;
