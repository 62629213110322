import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "components";
import auth from "lib/Auth";
import request from "lib/request";

class Login extends Component {
  state = {
    email: "",
    password: "",
    loginResponse: null,
    doingLogin: false
  };

  submitLogin = event => {
    this.setState({
      doingLogin: true
    });
    event.preventDefault();
    this.loginRequest = request("login", {
      redirectOnUnauthorized: false,
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password
      })
    });

    this.loginRequest.promise
      .then(response => {
        this.setState({
          doingLogin: false
        });
        if (response.clubAccountId) {
          localStorage.setItem("stripeKey", response.clubAccountId);
        } else {
          localStorage.removeItem("stripeKey");
        }
        if (response.clubId) {
          localStorage.setItem("clubId", response.clubId);
        } else {
          localStorage.removeItem("clubId");
        }

        if (!response.error) {
          if (response.token) {
            auth.authToken = response.token;
          }
        } else {
          let message = "";
          switch (response.error.status) {
            case 400:
            case 401:
              message = response.body.message;
              break;
            case 500:
              message = "Error desconocido en el servidor";
              break;
            default:
              message = `Error desconocido ${response.error.status}`;
          }
          this.setState({
            loginResponse: {
              type: "error",
              message
            }
          });
        }
      })
      .catch(error => {
        this.setState({
          doingLogin: false,
          loginResponse: {
            type: "error",
            message:
              "Error en la comunicación con el servidor. Vuelva a intentarlo en unos minutos."
          }
        });
      });
  };

  componentWillUnmount() {
    if (this.loginRequest) this.loginRequest.cancel();
  }

  handleInputChange(self, event) {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    self.setState({
      [name]: value,
      loginResponse: null
    });
  }

  render() {
    const { email, password } = this.state;
    return (
      <Page
        className="page-container public-page login-page"
        showMenus={false}
        showProfile={false}
      >
        <div className="page-content">
          <h4 className="mr-auto ml-auto mb-4 text-center">Iniciar sesión</h4>
          <form
            onSubmit={this.submitLogin}
            className="flex flex-wrap register-step register-step-2 mb-8"
          >
            <div className="w-1 mr-auto ml-auto">
              <label className="block mb-2 leading-tight text-xs">Email</label>
              <input
                type="email"
                name="email"
                autoComplete="username"
                className="mb-4 leading-normal p-2 rounded"
                value={email}
                onChange={event => this.handleInputChange(this, event)}
                required="required"
              />
              <label className="block mb-2 leading-tight text-xs">
                Contraseña
              </label>
              <input
                type="password"
                name="password"
                autoComplete="current-password"
                className="mb-4 leading-normal p-2 rounded"
                value={password}
                onChange={event => this.handleInputChange(this, event)}
                required="required"
              />
              <div className="flex items-center justify-between mt-2">
                <button
                  className="uppercase py-3 font-medium bg-primary-hover text-white font-bold py-2 px-4 rounded text-sm"
                  type="submit"
                >
                  {this.state.doingLogin ? "Comprobando ..." : "Iniciar sesión"}
                </button>
                <Link
                  className="pl-5 inline-block align-baseline text-sm color-primary-hover no-underline"
                  to="/forgotten-password"
                >
                  ¿Olvidaste la contraseña?
                </Link>
              </div>
            </div>
          </form>
          {this.state.loginResponse && this.state.loginResponse.message ? (
            <div
              className={`login-response login-response-type-${
                this.state.loginResponse.type
              } text-center`}
            >
              {this.state.loginResponse.message}
            </div>
          ) : null}
        </div>
      </Page>
    );
  }
}

export default Login;
