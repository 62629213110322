import React from "react";
import { Page } from "components";
import {
  ResultsList,
  ResultsListItem,
  SearchResultsContainer
} from "views/Search";
import { VideoPreview } from "components";
import request from "lib/request";

class CategoryDetail extends React.Component {
  state = {
    videos: []
  };

  componentDidMount() {
    this.getCategory(parseInt(this.props.match.params.categoryId));
  }

  getCategory = categoryId => {
    const url =
      categoryId === 0
        ? `categories/expedition/products/${200}`
        : `categories/${categoryId}/products`;
    this.getCategoryRequest = request(url);
    this.getCategoryRequest.promise.then(({ productList, categoryName }) =>
      this.setState({
        videos: productList,
        categoryName:
          categoryName === "Iberalia Expeditions"
            ? "Expediciones"
            : categoryName
      })
    );
  };

  componentWillUnmount() {
    if (this.getCategoryRequest) this.getCategoryRequest.cancel();
  }

  playVideo = video => {
    if (video.series) {
      this.props.history.push(`/videoclub/serie/${video.id}`);
    } else {
      this.props.history.push(`/videoclub/video/${video.id}`);
    }
  };

  render() {
    const { videos, categoryName } = this.state;
    return (
      <Page showSearch={true} containerStyles={{ paddingTop: 50 }}>
        {videos.length !== 0 && (
          <SearchResultsContainer>
            <h2 style={{ marginBottom: 20 }}>Categoría: {categoryName}</h2>
            <ResultsList className="list-reset w-full">
              {videos.map(({ id, series, title, thumbnail }, index) => (
                <ResultsListItem
                  className={"w-full sm:w-1/2" + (videos.length > 2 ? " md:w-1/4" : "")}
                  key={index}
                >
                  <VideoPreview
                    title={title}
                    image={thumbnail ? thumbnail.url : undefined}
                    onPlayButtonClick={() => this.playVideo({ id, series })}
                  />
                </ResultsListItem>
              ))}
            </ResultsList>
          </SearchResultsContainer>
        )}
      </Page>
    );
  }
}

export default CategoryDetail;
