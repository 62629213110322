import { useEffect, useState } from "react";
import moment from "moment";

export default ({ data, textAfterData }) => {
  const [timeLeft, setTimeLeft] = useState(moment(data).diff(moment()));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(moment(data).diff(moment()));
    }, 1000);
    return () => {
      clearInterval(timer)
    }
  }, [data]);

  return (timeLeft > 0 ? dhm(timeLeft) : textAfterData)
}


const dhm = (t) => {
  let cd = 24 * 60 * 60 * 1000,
    ch = 60 * 60 * 1000,
    d = Math.floor(t / cd),
    h = Math.floor((t - d * cd) / ch),
    m = Math.round((t - d * cd - h * ch) / 60000),
    pad = function (n) {
      return n < 10 ? '0' + n : n;
    };
  if (m === 60) {
    h++;
    m = 0;
  }
  if (h === 24) {
    d++;
    h = 0;
  }
  return [d + `${d === 1 ? " día" : " días"}`, pad(h) + "h", pad(m) + "min"].join(' ');
};
