import Club from "./Club";
import ClubDetail from "./ClubDetail";
import Home from "./Home/Home";
import Live from "./Live";
import LiveProgramming from "./LiveProgramming";
import Login from "./Login";
import Novelties from "./Novelties";
import Register from "./Register";
import Videoclub from "./Videoclub";
import ShowDetail from "./ShowDetail";
import MovieDetail from "./MovieDetail";
import Account from "./Account";
import ResetPassword from "./ResetPassword";
import ForgottenPassword from "./ForgottenPassword";
import VerifyEmail from "./VerifyEmail";
import Play from "./Play";
import Subscribe from "./Subscribe";
import CategoryDetail from "./CategoryDetail";

export {
  Club,
  ClubDetail,
  Home,
  Live,
  LiveProgramming,
  Login,
  Novelties,
  Register,
  Videoclub,
  ShowDetail,
  MovieDetail,
  Account,
  ResetPassword,
  ForgottenPassword,
  VerifyEmail,
  Play,
  Subscribe,
  CategoryDetail
};
