import React from "react";
import styled from "styled-components";
import { mediaQueryBreakPoint } from "./responsive";

const images = {
  original: require("./images/iberalia_n.png"),
  pesca: require("./images/pesca_n.png"),
  caza: require("./images/caza_n.png")
};

const Container = styled.div`
  margin: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f1f1f1;
  text-align: center;
  color: #333333;
  font-family: Lato;
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: bold;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    text-align: center;
    font-size: 26pt;
    line-height: normal;
  }
`;

const Description = styled.p`
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 20px;
  color: #777777;
`;

const Body = styled.div`
  display: flex;
  margin: 50px auto;
  padding-left: 16.5%;
  padding-right: 16.5%;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    flex-direction: column;
  }
`;

const ChannelsContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    align-items: center;
  }
`;

const Image = styled.img`
  max-width: 100px;
  margin-bottom: 5px;
`;

const InfoContainer = styled.div`
  display: flex;
  text-align: left;
  margin-left: 50px;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    flex-direction: column;
    align-items: center;
    margin-left: 0;

    div {
      margin-top: 50px;
      padding: 0 !important;
      border: none !important;
    }
  }

  div {
    padding-left: 20px;
    padding-right: 20px;

    &:not(:last-child) {
      border-right: 2px solid;
    }

    h3 {
      margin-bottom: 10px;
    }

    p {
      color: #777777;
    }
  }
`;

export default () => {
  return (
    <Container>
      <Title>¿QUÉ ES IBERALIA GO?</Title>

      <Description>
        Es la nueva App Oficial que te permitirá disfrutar de todo el contenido
        de Iberalia Televisión.
      </Description>
      <Body>
        <ChannelsContainer>
          <Image src={images.original} />
          <Image src={images.pesca} />
          <Image src={images.caza} />
        </ChannelsContainer>
        <InfoContainer>
          <div>
            <h3>3 CANALES DE TV HD EN DIRECTO</h3>
            <p>
              Si no estas contento con nosotros, cancela tu suscripción en
              cualquier momento. Sin compromiso.
            </p>
          </div>
          <div>
            <h3>VIDEOCLUB CON MÁS DE 1.200 DOCUMENTALES</h3>
            <p>
              Caza mayor, Caza menor, Pesca mar, Pesca de río, Monterías,
              Repechos, África, Europa, Asia, América.
            </p>
          </div>
          <div>
            <h3>CLUB DE CAZA Y PESCA</h3>
            <p>Sorteos, Viajes, Concursos, Novedades, Noticias...</p>
          </div>
        </InfoContainer>
      </Body>
    </Container>
  );
};
