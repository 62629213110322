import React from "react";
import SiteNavigation from "./SiteNavigation";
import { Loading } from "components";

const Page = ({
  children,
  className = "",
  showOverlay = true,
  showSiteNavigation = true,
  showMenus = true,
  containerClassName = "",
  containerStyles,
  showSearch = false,
  showProfile = true,
  isLoading = false,
  onSearchClick = () => {}
}) => {
  return (
    <div
      className={`${className} page-container private-page full-width ${
        showSiteNavigation ? "with-site-navigation" : ""
      }`}
    >
      <Loading show={isLoading} />
      {showOverlay ? <div className="bkg-overlay" /> : null}
      {showSiteNavigation ? (
        <SiteNavigation
          menus={[
            // { title: "Directo", url: "/directo" },
            { title: "Videoclub", url: "/videoclub" },
            // { title: "Novedades", url: "/novedades" },
            { title: "Noticias", url: "/noticias" }
          ]}
          showMenus={showMenus}
          showSearch={showSearch}
          onSearchClick={onSearchClick}
          showProfile={showProfile}
        />
      ) : null}
      <div
        className={`page-content ${containerClassName}`}
        style={containerStyles}
      >
        {children}
      </div>
    </div>
  );
};

export default Page;
