import React from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";
import { Page, VideoPreview } from "components";
import LiveProgramming from "./LiveProgramming";
import { CTA } from "ui";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Swal from 'sweetalert2'

const Container = styled.div`
  position: relative;
  margin-top: 50px;
  text-align: center;
  padding-bottom: 100px;
`;

const SliderContainer = styled.div`
  display: block;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  max-width: 60%;

  .slick-list {
    overflow: inherit;
  }

  .slick-slide {
    display: inline-block;
  }

  .slick-slide.slick-current .channel {
    transform: none;
    transition: 0.5s ease-in-out;
  }

  .slick-slide .channel {
    transform: scaleY(0.9);
    transition: 0.5s ease-in-out;
  }
`;

const Channel = styled.div`
  position: relative;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
`;

const VerticalChannel = styled(Channel)`
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
`;

const Footer = styled.div`
  position: fixed;
  bottom: 0;
  height: 100px;
  width: 100%;
  background-color: transparent;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 2%;
  padding-right: 2%;
`;

const Text = styled.h1`
  color: white;
`;

class Live extends React.Component {
  state = {
    activeSlide: 1,
    activeChannelId: 1
  };

  settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToPeek: 1,
    slidesToScroll: 1,
    initialSlide: this.state.activeSlide,
    beforeChange: (current, next) => this.setState({ activeSlide: next }),
    focusOnSelect: true
  };

  channels = [
    {
      id: 2,
      title: "Iberalia 100% Pesca",
      image: "images/iberalia_pesca.jpg"
    },
    {
      id: 1,
      title: "Iberalia Original HD",
      image: "images/iberalia_original.jpg"
    },
    {
      id: 3,
      title: "Iberalia 100% Caza",
      image: "images/iberalia_caza.jpg"
    }
  ];

  goToSlide = index => {
    const activeSlide = this.state.activeSlide + index;
    this.sliderRef.slickGoTo(activeSlide);
    const activeChannelId = this.channels[activeSlide].id;
    this.setState({ activeSlide, activeChannelId });
  };

  handleChannelClick = ({ video: { id } }) => {
    //if subscriptionBlocked == undefined does nothing
    if (this.state.subscriptionBlocked) {
      Swal.fire({
        title: 'Suscripción Caducada',
        text: 'No tienes ninguna suscripción activa en este momento, subscribete ahora para poder ver contenidos como este.',
        type: 'warning',
        position: 'center',
        background: '#000',
        showConfirmButton: true,
        confirmButtonText: 'Gestionar suscripción',
        showCancelButton: true,
        cancelButtonText: 'OK',
      }).then((result) => {
        if (result.value) {
          this.props.history.push('/perfil/estado-suscripcion')
        }
      })
    } else if (this.state.subscriptionBlocked === false) {
      this.props.history.push("/channel/" + id);
    }
  };

  render() {
    const { activeSlide, activeChannelId } = this.state;
    return (
      <Page
        containerStyles={{
          overflow: "hidden",
          display: "flex",
          flex: "1 1 auto",
          flexFlow: "column nowrap",
          flexWrap: "nowrap",
          alignItems: "stretch",
          justifyContent: "flex-start"
        }}
      >
        <Container>
          <MediaQuery query="(max-width: 992px)">
            {this.channels.map(({ id, title, image }, index) => (
              <div key={index}>
                <VerticalChannel className="channel">
                  <VideoPreview
                    onPlayButtonClick={this.handleChannelClick}
                    showAlwaysPlayButton={true}
                    video={{ id }}
                    iconSize="2x"
                    title={title}
                    image={image}
                    imageMode="cover"
                  />
                </VerticalChannel>
                <CTA
                  title="Ver la programación"
                  url={`/directo/${id}/programacion`}
                  style={{ marginLeft: "1.5rem", marginRight: "1.5rem" }}
                />
              </div>
            ))}
          </MediaQuery>
          <MediaQuery query="(min-width: 992px)">
            <SliderContainer>
              <Slider
                {...this.settings}
                ref={slider => (this.sliderRef = slider)}
              >
                {this.channels.map(({ id, image }, index) => (
                  <div key={index}>
                    <Channel className="channel">
                      <VideoPreview
                        onPlayButtonClick={this.handleChannelClick}
                        video={{ id }}
                        image={image}
                        imageMode="cover"
                        iconSize="3x"
                      />
                    </Channel>
                  </div>
                ))}
              </Slider>
            </SliderContainer>
            <div
              style={{
                maxWidth: "80rem",
                margin: "auto",
                display: "flex",
                justifyContent: "center"
              }}
            >
              <LiveProgramming isPage={false} channelId={activeChannelId} onSubscriptionDataObtained={this.onSubscriptionDataObtained}/>
            </div>
            <Footer>
              {activeSlide !== 0 ? (
                this.renderSlideNavigation({
                  direction: "left",
                  textContainerStyles: { marginLeft: 10 },
                  icon: "arrow-circle-left",
                  goTo: -1
                })
              ) : (
                <div style={{ width: 300 }} />
              )}
              <Text style={{ width: 300 }} />
              {activeSlide !== 2 ? (
                this.renderSlideNavigation({
                  direction: "right",
                  textContainerStyles: { marginRight: 10 },
                  icon: "arrow-circle-right",
                  goTo: 1
                })
              ) : (
                <div style={{ width: 300 }} />
              )}
            </Footer>
          </MediaQuery>
        </Container>
      </Page>
    );
  }

  onSubscriptionDataObtained = (val) => {
    this.setState({subscriptionBlocked: val})
  }

  renderSlideNavigation = ({ direction, textContainerStyles, goTo, icon }) => {
    const { activeSlide } = this.state;
    const { title } = this.channels[activeSlide + goTo];
    return (
      <div
        className="flex"
        style={{ width: 300, cursor: "pointer" }}
        onClick={() => this.goToSlide(goTo)}
      >
        {direction === "left" ? (
          <FontAwesomeIcon icon={icon} size="3x" />
        ) : null}
        <div style={textContainerStyles}>
          <Text>{title}</Text>
        </div>
        {direction === "right" ? (
          <FontAwesomeIcon icon={icon} size="3x" />
        ) : null}
      </div>
    );
  };
}

export default Live;
