import React from "react";
import { FormField } from "ui";

const PersonalInformation = ({
  defaultValues: { email, firstName, lastName, address, city, postalCode, country, birthdate, phone } = {},
  handleSubmit = () => {}
}) => {
  const onSubmit = event => {
    event.preventDefault();

    handleSubmit({
      values: formatElementsToObject([...event.target.elements]),
      form: "personal-information"
    });
  };

  const formatElementsToObject = values => {
    return values.reduce(
      (reducer, { name, value }) => ({ ...reducer, [name]: value }),
      {}
    );
  };

  return (
    <form onSubmit={onSubmit}>
      <FormField
        title="Correo Electrónico"
        name="email"
        value={email}
        type="email"
      />
      <FormField title="Nombre" name="firstName" value={firstName} />
      <FormField title="Apellidos" name="lastName" value={lastName} />
      <FormField title="Dirección" name="address" value={address} />
      <FormField title="Ciudad" name="city" value={city} />
      <FormField title="Código Postal" name="postalCode" value={postalCode} />
      <FormField title="País" name="country" value={country} />
      <FormField
        title="Fecha de Nacimiento"
        name="birthdate"
        value={birthdate}
        type="date"
      />
      <FormField title="Telefono" name="phone" value={phone} />
      <button
        className="bg-primary-hover font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        type="submit"
      >
        GUARDAR
      </button>
    </form>
  );
};

export default PersonalInformation;
