import React from "react";
import styled from "styled-components";
import VideoPreview from "./VideoPreview";
import Swal from 'sweetalert2'


const ListContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  margin-bottom: 0;
  margin-left: -0.9375rem;
  margin-right: -0.9375rem;
  padding-left: 0;
`;

const ListItem = styled.li`
  flex: 0 0 auto;
  margin-bottom: 1.25rem;

  @media (min-width: 800px) {
    padding-left: 0.9375rem;
    padding-right: 0.9375rem;
  }

  padding-left: 0.3125rem;
  padding-right: 0.3125rem;
`;

const SeasonDetail = ({ show, match, history }) => {
  const season = show.seasonList.find(
    ({ num }) => num === parseInt(match.params.seasonId)
  );

  const onPlayButtonClick = ({ event, video }) => {
    if (video.video.subscriptionBlocked) {
      Swal.fire({
        title: 'Suscripción Caducada',
        text: 'No tienes ninguna suscripción activa en este momento, subscribete ahora para poder ver contenidos como este.',
        type: 'warning',
        position: 'center',
        background: '#000',
        showConfirmButton: true,
        confirmButtonText: 'Gestionar suscripción',
        showCancelButton: true,
        cancelButtonText: 'OK',
      }).then((result) => {
        if (result.value) {
          history.push('/perfil/estado-suscripcion')
        }
      })
    } else {
      history.push(`/player/${show.id}/${video.id}`);
    }
  };

  return season ? (
    <div className="show-info-container">
      <div>
        <ListContainer className="list-reset flex-wrap">
          {season.episodeList.map((episode, index) => (
            <ListItem key={index} className="w-full sm:w-1/2 md:w-1/3">
              <VideoPreview
                title={`${episode.num}. ${episode.title}`}
                iconSize="3x"
                video={episode}
                image={episode.image ? episode.image.url : undefined}
                onPlayButtonClick={onPlayButtonClick}
              />
            </ListItem>
          ))}
        </ListContainer>
      </div>
    </div>
  ) : null;
};

export default SeasonDetail;
