import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SeasonsContainer = styled.div`
  width: 100%;
  max-width: 80rem;
  position: relative;
  padding-left: 2.9rem;
  padding-right: 2.9rem;
  margin-left: auto;
  margin-right: auto;
`;

const Nav = styled.div`
  position: relative;
  height: 3.5rem;
  overflow: hidden;
`;

const SelectorContainer = styled.div`
  box-shadow: inset 0 -0.0625rem 0 0 hsla(0, 0%, 100%, 0.5);
  height: 100%;
  position: relative;
`;

const ListContainer = styled.div`
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  height: calc(100% + 1.25rem);
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
`;

const List = styled.ul`
  max-width: 100%;
  margin: 0;
  position: relative;
  white-space: nowrap;
  display: inline-block;
  padding: 0;
`;

const ListItem = styled.li`
  opacity: 0.5;
  margin-left: 0.9375rem;
  margin-right: 0.9375rem;
  max-width: 16rem;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  flex-shrink: 0;

  :hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
`;

const Link = styled.a`
  display: block;
  text-align: center;
  line-height: 3.5rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  text-decoration: none;
  color: white;
  cursor: pointer;
`;

const Button = styled.button`
  opacity: 0.2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.875rem;
  color: white;
  background: black;
  position: absolute;
  top: 0;
  bottom: 0.25rem;
  border: none;
`;

const LeftButton = styled(Button)`
  left: 0;
`;

const RightButton = styled(Button)`
  right: 0;
`;

const SeasonSelector = ({
  seasons = [],
  activeIndex = 0,
  onSeasonClick = () => {}
}) => {
  const distanceToScroll = 150;
  const scrollRef = React.createRef();

  const scroll = (direction = "right") => {
    scrollRef.current.scrollLeft +=
      direction === "left" ? distanceToScroll * -1 : distanceToScroll;
  };

  const renderListItem = (season, index) => (
    <ListItem
      key={index}
      className={`${activeIndex === index ? "active" : ""}`}
    >
      <Link
        onClick={() => onSeasonClick(season, index)}
        className={`${activeIndex === index ? "active" : ""}`}
      >
        <span>{season.title}</span>
      </Link>
    </ListItem>
  );

  return (
    <SeasonsContainer>
      <Nav>
        <SelectorContainer>
          <ListContainer ref={scrollRef}>
            <List>
              {seasons.map((season, index) => renderListItem(season, index))}
              <li>
                <div style={{ transform: "translate(15px)", width: "75px" }} />
              </li>
            </List>
          </ListContainer>
          <LeftButton type="button" onClick={() => scroll("left")}>
            <FontAwesomeIcon icon="chevron-left" />
          </LeftButton>
          <RightButton type="button" onClick={() => scroll("right")}>
            <FontAwesomeIcon icon="chevron-right" />
          </RightButton>
        </SelectorContainer>
      </Nav>
    </SeasonsContainer>
  );
};

export default SeasonSelector;
