import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  border: 1px solid white;
  border-radius: 10px;
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: #2a303f;
  font-family: Roboto;
  text-align: center;
  margin-bottom: 5px;
`;

const Title = styled.h2`
  font-size: 15pt;
  color: white;
`;

const ItemContainer = styled.div`
  margin-bottom: 5px;
`;

const ProgrammingList = ({ title = "", items = [], renderItem = () => {} }) => {
  return (
    <React.Fragment>
      <Container>
        <Title>{title}</Title>
      </Container>
      {items.map((item, index) => (
        <ItemContainer key={index}>{renderItem(item, index)}</ItemContainer>
      ))}
    </React.Fragment>
  );
};

export default ProgrammingList;
