import React, { useState } from "react";
import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

export default ({ onPaymentMethodCreate = () => null }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setSubmitting(true);
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      setSubmitting(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    // Use your card Element with other Stripe.js APIs
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
      alert("La tarjeta introducida no es valida, asegurese que los datos sean correctos.")
    } else {
      onPaymentMethodCreate(paymentMethod);
    }
    setSubmitting(false);
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <button
        className="py-3 mt-4 font-medium bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded text-sm"
        type="submit"
        disabled={submitting}
      >
        {submitting ? "Guardando..." : "Guardar tarjeta"}
      </button>
    </form>
  );
};
