import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { mediaQueryBreakPoint } from "./responsive";

const Container = styled.div`
  margin: 10px auto;
  margin-left: 16.5%;
  margin-right: 16.5%;
  margin-bottom: 20px;
`;

const List = styled.ul`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
  width: 100%;
  list-style: none;

  li {
    margin-right: 10px;
  }

  @media (max-width: ${mediaQueryBreakPoint}px) {
    flex-direction: column;

    li {
      margin-bottom: 10px;
    }
  }
`;

const SocialIconsListItem = styled.li`
  display: flex;
  text-align: center;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    margin-top: 10px;
  }
`;

const SocialIcon = styled(FontAwesomeIcon)`
  color: #878787;
  margin-right: 15px;
`;

const social = [
  "facebook-f",
  "twitter",
  "instagram",
  "youtube",
  "google",
  "linkedin"
];

export default () => {
  return (
    <Container>
      <List>
        <li>¿Cómo ver IberaliaGo?</li>
        <li>Sobre Nosotros</li>
        <li>Contacto</li>
        <li>Aviso Legal</li>
        <li>Política de Privacidad</li>
        <SocialIconsListItem>
          {social.map((icon, index) => (
            <SocialIcon key={index} size={"2x"} icon={["fab", icon]} />
          ))}
        </SocialIconsListItem>
      </List>
      <p>© 2018 IberaliaGo. Todos los derechos reservados.</p>
    </Container>
  );
};
