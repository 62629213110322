import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none;
  display: block;
  text-align: center;
  border-radius: 10px;
  font-weight: 600;

  @media (max-width: 900px) {
    font-size: 8pt;
  }
`;

const CTA = ({ title, url = "#", style, className, onClick = () => {} }) => {
  return (
    <StyledLink
      to={url}
      onClick={onClick}
      className={`font-semibold text-white py-2 px-4 ${className}`}
      style={style}
    >
      {title ? title.toUpperCase() : null}
    </StyledLink>
  );
};

export default CTA;
