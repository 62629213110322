import React, { Component } from "react";
import { Page } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import request from "lib/request";
import ReactPlayer from 'react-player'

const UNKNOWN = 0;
const MOVIE = 1;
const EPISODE = 2;
const CHANNEL = 2;

const IOS = typeof navigator !== 'undefined' && (/iPad|iPhone|iPod/.test(navigator.userAgent) || /^((?!chrome|android).)*safari/i.test(navigator.userAgent));

class Play extends Component {
  constructor(props) {
    super(props);

    const {
      episode_id,
      serie_id,
      movie_id,
      movie_id_trailer,
      channel_id,
      inProduction_id,
      commercial_id
    } = this.props.match.params;
    let startPlaying = false;

    this.product = {};

    if (movie_id) {
      this.fetchMovieData(parseInt(movie_id));
    } else if (movie_id_trailer) {
      this.fetchMovieDataTrailer(parseInt(movie_id_trailer));
    } else if (episode_id && serie_id) {
      this.fetchEpisodeData(parseInt(serie_id), parseInt(episode_id));
    } else if (channel_id) {
      this.fetchChannelData(parseInt(channel_id));
      startPlaying = true;
    } else if (inProduction_id) {
      this.fetchInProductionData(parseInt(inProduction_id));
      startPlaying = true;
    } else if (commercial_id) {
      this.fetchCommercialData(parseInt(commercial_id));
      startPlaying = true;
    } else {
      this.product.type = UNKNOWN;
      this.product.id = 0;
    }

    this.state = {
      title: "",
      status: "fetching",
      video: {
        url: "",
        config: {}
      },
      statusMessage: "Loading data...",
      interaction: true,
      startPlaying,
      playing: true
    };

    this.playerStats = {
      startTime: -1,
      currentPlaytimeInSec: -1
    };

    this.mouseMovement = null;
  }

  close() {
    this.props.history.goBack();
  }

  findEpisodeFromSeasons(seasons, episodeId) {
    let episodeFound = false;
    seasons.some(season =>
      season.episodeList.some(episode => {
        const match = episode.id === episodeId;
        if (match) {
          episodeFound = Object.assign({ season }, episode);
        }
        return match;
      })
    );
    return episodeFound;
  }

  getChannelUrl(id) {
    switch (id) {
      case 1:
        return "https://directo.fibwi.com/stream/stream_iberaliaHD/7000K/iberaliaHD.m3u8";
      case 2:
        return "https://directo.fibwi.com/stream/stream_iberaliaHDPESCA/7000K/iberaliaHDPESCA.m3u8";
      default:
        return "https://directo.fibwi.com/stream/stream_iberaliaHDCAZA/7000K/iberaliaHDCAZA.m3u8";
    }
  }

  fetchChannelData(channelId) {
    this.playerRequest = request("channels");
    this.playerRequest.promise.then(response => {
      if (response.channelList) {
        const channel = response.channelList.find(c => c.id === channelId);

        if (channel && channel.video) {
          let url;
          let config;

          if (channel.video.hlsUrl) {
            config = {
              forceHLS: true
            };

            url = this.getChannelUrl(channelId) + channel.video.hash;
          }

          this.product = {
            id: channelId,
            type: CHANNEL
          };

          this.setState({
            title: `${channel.name}`,
            video: {
              url,
              config
            },
            statusMessage: "Successful fetch",
            status: "fetched"
          });
        } else {
          this.setState({
            statusMessage: "No se ha podido obtener información del canal",
            status: "error_fetching"
          });
        }
      }
    });
  }

  fetchInProductionData = id => {
    this.inProductionRequest = request("in-production");
    this.inProductionRequest.promise.then(({ inProductionList }) => {
      const product = inProductionList[0];
      if (product) {
        this.setState({
          title: product.title,
          video: {
            url: product.video.hlsUrl + product.video.hash,
            config: {
              forceHLS: true
            }
          },
          statusMessage: "Successful fetch",
          status: "fetched"
        });
      }
    });
  };

  fetchEpisodeData(showId, episodeId) {
    this.playerRequest = request(`products/${showId}`);
    this.playerRequest.promise
      .then(response => {
        if (!response.error) {
          let url;
          let config;

          const episode = this.findEpisodeFromSeasons(
            response.seasonList,
            episodeId
          );

          if (episode) {
            if (episode.video) {
              if (episode.video.hlsUrl) {
                config = {
                  forceHLS: true
                };
                url = episode.video.hlsUrl + episode.video.hash;
              }
            }

            this.product = {
              id: showId,
              episodeNum: episode.num,
              seasonNum: episode.season.num,
              type: EPISODE
            };

            this.setState({
              title: `${response.title} / T${episode.season.num}:E${
                episode.num
              }${episode.title ? " - " + episode.title : ""}`,
              video: {
                url,
                config
              },
              statusMessage: "Successful fetch",
              status: "fetched"
            });
          } else {
            console.error(
              `Episodio ${episodeId} de serie ${showId} no encontrado`
            );
          }
        } else if (response.error) {
          this.setState({
            statusMessage: response.error.message,
            status: "error_fetching"
          });
        } else {
          this.setState({
            statusMessage: "Unknown Error",
            status: "error_fetching"
          });
        }
      })
      .catch(error => {
        this.setState({
          statusMessage: error.message,
          status: "error_fetching"
        });
      });
  }

  fetchMovieData(movieId) {
    this.playerRequest = request(`products/${movieId}`);
    this.playerRequest.promise
      .then(response => {
        if (!response.error) {
          let data = response;
          let url;
          let config;

          if (response.video && response.video.hlsUrl) {
            config = {
              forceHLS: true
            };
            url = response.video.hlsUrl + response.video.hash;
          }

          this.product = {
            id: movieId,
            type: MOVIE
          };

          this.setState({
            title: data.title,
            video: {
              url,
              config
            },
            statusMessage: "Successful fetch",
            status: "fetched"
          });
        } else if (response.error) {
          this.setState({
            statusMessage: response.error.message,
            status: "error_fetching"
          });
        } else {
          this.setState({
            statusMessage: "Unknown Error",
            status: "error_fetching"
          });
        }
      })
      .catch(error => {
        this.setState({
          statusMessage: error.message,
          status: "error_fetching"
        });
      });
  }



  fetchMovieDataTrailer(movieId) {
    this.playerRequest = request(`products/${movieId}`);
    this.playerRequest.promise
      .then(response => {
        if (!response.error) {
          let data = response;
          let url;
          let config;

          if (response.trailer && response.trailer.hlsUrl) {
            config = {
              forceHLS: true
            };
            url = response.trailer.hlsUrl + response.trailer.hash;
          }

          this.product = {
            id: movieId,
            type: MOVIE
          };

          this.setState({
            title: data.title,
            video: {
              url,
              config
            },
            statusMessage: "Successful fetch",
            status: "fetched"
          });
        } else if (response.error) {
          this.setState({
            statusMessage: response.error.message,
            status: "error_fetching"
          });
        } else {
          this.setState({
            statusMessage: "Unknown Error",
            status: "error_fetching"
          });
        }
      })
      .catch(error => {
        this.setState({
          statusMessage: error.message,
          status: "error_fetching"
        });
      });
  }

  fetchCommercialData(commercialId) {
    this.playerRequest = request(`club/partners/`);
    this.playerRequest.promise
      .then(response => {
        const commercial = response.partnerList
          .reduce(
            (reducer, { commercialList }) => [...reducer, ...commercialList],
            []
          )
          .find(({ id }) => id === commercialId);

        if (!response.error) {
          let url;
          let config;

          if (commercial.video && commercial.video.hlsUrl) {
            config = {
              forceHLS: true
            };
            url = commercial.video.hlsUrl + commercial.video.hash;
          }

          this.product = {
            id: commercialId,
            type: MOVIE
          };

          this.setState({
            title: commercial.title,
            video: {
              url,
              config
            },
            statusMessage: "Successful fetch",
            status: "fetched"
          });
        } else if (response.error) {
          this.setState({
            statusMessage: response.error.message,
            status: "error_fetching"
          });
        } else {
          this.setState({
            statusMessage: "Unknown Error",
            status: "error_fetching"
          });
        }
      })
      .catch(error => {
        this.setState({
          statusMessage: error.message,
          status: "error_fetching"
        });
      });
  }

  startedPlayer() {
    this.setState({
      status: "playing",
      interaction: false
    });

    this.playerStats = {
      currentPlaytimeInSec: -1,
      startTime: new Date().toISOString()
    };
  }

  pausedPlayer() {
    this.sendPlayerStats();
    this.setState({
      status: "pauses",
      interaction: true
    });
  }

  endedPlayer() {
    this.sendPlayerStats();
    this.setState({
      status: "ended",
      interaction: true
    });

    switch (this.product.type) {
      case MOVIE:
        this.props.history.push("/videoclub/video/" + this.product.id);
        break;
      case EPISODE:
        this.props.history.push("/videoclub/serie/" + this.product.id);
        break;
      default:
        break;
    }
  }

  inProgressPlayer(data) {
    const currentPlaytimeInSec = data.playedSeconds;

    if (currentPlaytimeInSec) {
      if (this.playerStats.currentPlaytimeInSec === -1) {
        const startTime = new Date().toISOString();
        this.playerStats = {
          startTime,
          currentPlaytimeInSec
        };

        this.sendPlayerStats();
      } else {
        this.playerStats.currentPlaytimeInSec = currentPlaytimeInSec;
      }
    }
  }

  sendPlayerStats() {
    let url;
    switch (this.product.type) {
      case MOVIE:
        url = `products/${this.product.id}/playing`;
        break;
      case EPISODE:
        url = `products/${this.product.id}/seasons/${
          this.product.seasonNum
        }/episodes/${this.product.episodeNum}/playing`;
        break;
      case CHANNEL:
        url = `channels/${this.product.id}/playing`;
        break;
      default:
        url = false;
    }

    if (url) {
      let playerRequest = request(url, {
        method: "POST",
        body: JSON.stringify(this.playerStats)
      });

      playerRequest.promise.then(response => {});
    } else {
      console.warn("Unknown product type");
    }
  }

  movingMouse() {
    if (this.state.status === "playing") {
      if (this.mouseMovement) {
        clearTimeout(this.mouseMovement);
      }

      this.mouseMovement = setTimeout(() => {
        if (this.state.status === "playing") {
          this.mouseMovement = null;
          this.setState({
            interaction: false
          });
        }
      }, 2000);

      this.setState({
        interaction: true
      });
    } else if (this.mouseMovement) {
      clearTimeout(this.mouseMovement);
      this.mouseMovement = null;
      this.setState({
        interaction: false
      });
    }
  }

  ref = player => {
    this.player = player;
  };

  componentWillUnmount() {
    if (this.mouseMovement) {
      clearTimeout(this.mouseMovement);
      this.mouseMovement = null;
    }
  }

  render() {
    return (
      <Page
        className={`player-page player-status-${this.state.status} ${
          this.state.interaction ? "interaction" : "no-interaction"
        }`}
        showSiteNavigation={false}
        showOverlay={false}
        onMouseMove={() => this.movingMouse()}
      >
        <div>
          <h2 className="player-title">
            <FontAwesomeIcon
              icon="arrow-left"
              className="clickable"
              onClick={() => this.close()}
            />
            &nbsp;&nbsp;&nbsp;{this.state.title}
          </h2>
          {this.state.video.url ? (
            <div
              className="player-wrapper"
              onMouseMove={() => this.movingMouse()}
            >
              <ReactPlayer
                ref={this.ref}
                className="react-player"
                url={ this.state.video.url}
                playing={this.state.playing}
                controls
                width="100%"
                height="100%"
                config={{
                  file: {
                    forceHLS: !IOS,
                    ...this.state.videoConfig
                  }
                }}
                progressInterval={5000}
                onPlay={() => this.startedPlayer()}
                onPause={() => this.pausedPlayer()}
                onEnded={() => this.endedPlayer()}
                onProgress={data => this.inProgressPlayer(data)}

              />
            </div>
          ) : null}
        </div>
        {this.state.status === "error_fetching" ? (
          <div className="player-video-error-wrapper">
            <div className="player-video-error">
              <h3>Algo ha fallado !</h3>
              <br />
              <p>{this.state.statusMessage}</p>
            </div>
          </div>
        ) : null}
      </Page>
    );
  }
}

export default Play;
