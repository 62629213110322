import React from "react";
import styled from "styled-components";
import backgroundImage from "./images/trama2.png";
import devicesImage from "./images/pantallas.png";
import chromecastIcon from "./images/icono_chromecast.png";
import tabletIcon from "./images/icono_tablet.png";
import laptopIcon from "./images/icono_portatil.png";
import { mediaQueryBreakPoint } from "./responsive";

const Container = styled.div`
  margin: auto;
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: #f1f1f1;
  text-align: center;
  color: #777777;
  font-family: Lato;
  background: url("${backgroundImage}"), radial-gradient(ellipse at top,rgba(80,79,80,1) 0,rgba(0,0,0,1) 100%);
`;

const Title = styled.h2`
  color: white;
  font-size: 50px;
  font-weight: 600;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    text-align: center;
    font-size: 26pt;
    line-height: normal;
  }
`;

const Body = styled.div`
  margin: 10px auto;
  margin-left: 16.5%;
  margin-right: 16.5%;
  text-align: center;
`;

const Subtitle = styled.h3`
  font-size: 16px;
  font-weight: 600;
  border-top: 2px solid #fff;
  border-bottom: 2px solid #fff;
  padding: 10px;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 600;
`;

const ImagesContainer = styled.div`  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 10px;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  height: 100px;
  margin-bottom: 5px;
`;

export default () => {
  return (
    <Container>
      <img alt="devices" src={devicesImage} />
      <Title>LLÉVATELO CONTIGO ESTÉS DONDE ESTÉS</Title>
      <Body>
        <Subtitle>
          DISFRUTA DE IBERALIA TV DESDE CUALQUIER DISPOSITIVO, EN CUALQUIER
          LUGAR
        </Subtitle>
        <ImagesContainer>
          <div>
            <Image src={chromecastIcon} />
            <Text>CHROMECAST</Text>
          </div>
          <div>
            <Image src={tabletIcon} />
            <Text>MÓVIL Y TABLET</Text>
          </div>
          <div>
            <Image src={laptopIcon} />
            <Text>WEB</Text>
          </div>
        </ImagesContainer>
      </Body>
    </Container>
  );
};
