import React, { Component } from "react";
import Hammer from "react-hammerjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

const Title = styled.h3`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 24px;
`;

const PanButton = styled.button`
  position: relative;
  margin-right: 10px;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid;
  vertical-align: top;
  color: white;

  @media (max-width: 576px) {
    width: 24px;
    height: 24px;
  }
`;

const StyledIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 15px;
  transform: translate(-42%, -50%);
`;

class VideoCarousel extends Component {
  constructor(props) {
    super(props);

    this.wrapper = React.createRef();
    this.panOptions = {
      touchAction: "compute",
      recognizers: {
        pan: {
          threshold: 5
          // velocity: 0.1
        }
      }
    };
    this.tapOptions = {
      touchAction: "compute",
      recognizers: {
        tap: {
          time: 600,
          threshold: 100
        }
      }
    };

    this.numberOfElements = this.props.renderFirstItem ? 3 : 4;

    this.state = {
      displacement: this.numberOfElements,
      wrapperWidth: this.numberOfElements + props.items.length * 24.5,
      currentItemPosition: 0,
      maxItemIndex: props.items.length - this.numberOfElements,
      transitionDuration: 0,
      items: []
    };
  }

  handleWheel(event) {
    if (event.deltaX) {
      event.preventDefault();
      const currentWidth = this.wrapper.current.parentNode.offsetWidth;
      const direction = Math.abs(event.deltaX) / event.deltaX;
      let realPercentageDisplacement =
        (direction * 100 * Math.abs(event.deltaX)) / currentWidth;
      let displacement = this.state.displacement - realPercentageDisplacement;
      displacement = Math.min(
        this.numberOfElements,
        Math.max(
          this.numberOfElements -
            23.25 * (this.props.items.length - this.numberOfElements) -
            (this.props.items.length - this.numberOfElements),
          displacement
        )
      );

      this.setState({
        displacement: displacement,
        transitionDuration: 20
      });
    }
  }

  handlePan = event => {
    const currentWidth = this.wrapper.current.parentNode.offsetWidth;
    const direction = (-1 * Math.abs(event.deltaX)) / event.deltaX;
    const portion = 24.25;

    let newPositionIndex =
      this.state.currentItemPosition +
      direction *
        Math.ceil((100 * Math.abs(event.deltaX)) / (portion * currentWidth));
    newPositionIndex = Math.max(
      0,
      Math.min(this.state.maxItemIndex, newPositionIndex)
    );

    const newDisplacement = this.numberOfElements - portion * newPositionIndex;

    this.setState({
      displacement: newDisplacement,
      currentItemPosition: newPositionIndex,
      transitionDuration: 300
    });
  };

  render() {
    const {
      renderTitle = () => {},
      renderViewMore = () => {},
      title,
      renderFirstItem,
      renderItem = () => {},
      onTap = () => {}
    } = this.props;
    return (
      <div
        className="pt-4 pb-4"
        ref={this.wrapper}
        style={{
          overflow: "hidden",
          // backgroundImage: "linear-gradient(#291c1e, #000)"
        }}
      >
        <div className="flex justify-between" style={{marginBottom: "-10px"}}>
          <Title className="leading-loose carousel-title font-bold">
            {title || renderTitle()}
          </Title>
          <div
            style={{ flexShrink: 0, display: "flex", alignItems: "center" }}
            className="sm:pb-5"
          >
            {renderViewMore()}
            <PanButton
              onClick={event => {
                event.deltaX = 500;
                this.handlePan(event);
              }}
            >
              <StyledIcon
                icon="chevron-left"
                style={{ transform: "translate(-58%,-50%)" }}
              />
            </PanButton>
            <PanButton
              onClick={event => {
                event.deltaX = -500;
                this.handlePan(event);
              }}
            >
              <StyledIcon icon="chevron-right" />
            </PanButton>
          </div>
        </div>
        <div
          className="flex"
          style={{ marginLeft: renderFirstItem ? "4%" : undefined }}
        >
          {renderFirstItem ? (
            <div className="video-item flex-no-shrink">{renderFirstItem()}</div>
          ) : null}
          <div style={{ overflow: "hidden" }}>
            <div
              onWheel={event => this.handleWheel(event)}
              className="video-carousel-wrapper flex"
              style={{
                transitionDuration: `${this.state.transitionDuration}ms`,
                transitionTimingFunction: "ease-out",
                transform: `translate3d(${this.state.displacement.toFixed(
                  2
                )}vw, 0px, 0px)`,
                width: `${4 + this.props.items.length * 24.5}vw`
              }}
            >
              {this.props.items.map((video, index) => {
                return (
                  <Hammer
                    key={index}
                    options={this.tapOptions}
                    onTap={() => onTap(video)}
                  >
                    <div className="video-item">{renderItem(video)}</div>
                  </Hammer>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VideoCarousel;
