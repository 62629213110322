import React, { Component } from "react";
import { Page, Contest } from "components";
import request from "lib/request";
import styled from "styled-components";
import { CTA } from "ui";
import Swal from 'sweetalert2'

const DescriptionContainer = styled.div`
  padding: 50px;
  line-height: 1.5;
`;
class ClubDetail extends Component {
  state = {
    title: "",
    description: "",
    image: ""
  };

  urls = [
    {
      type: "noticias",
      requestURL: "news"
    }
  ];

  componentDidMount() {
    const type = this.props.location.pathname.split("/")[1];
    const id = this.props.match.params.id;
    this.getData(type, id);
  }

  componentWillUnmount() {
    if (this.dataRequest) this.dataRequest.cancel();
    if (this.activityParticipantRequest)
      this.activityParticipantRequest.cancel();
  }

  getData = (type, id) => {
    const url = this.urls.find(url => url.type === type);

    if (url) {
      const { requestURL } = url;
      this.dataRequest = request(`${requestURL}/${id}`);
      this.dataRequest.promise.then(response =>
        this.setState({ ...response, type, requestURL })
      );
    } else {
      this.setState({ description: "Esta página no existe" });
    }
  };

  addActivityParticipant = () => {
    const { requestURL } = this.state;
    const id = this.props.match.params.id;
    this.activityParticipantRequest = request(`${requestURL}/${id}/participants`, {
      method: "POST"
    });
    this.activityParticipantRequest.promise.then(response => {
      this.setState({
        participating: response && (!response.error || response.body.message === "Usuario ya se unió anteriormente"),
        errorResponse: (response && response.error) ? response : null
      });
    });
  };

  render() {
    const {
      title,
      description,
      image,
      modality,
      modalityImage,
      program,
      programImage,
      datesPriceSeats,
      datesPriceSeatsImage,
      type,
      participating,
      errorResponse
    } = this.state;

    if (!participating && errorResponse && errorResponse.error.status === 400) {
      Swal.fire({
        title: 'Parece que no has rellenado tus datos',
        text: errorResponse.body.message,
        type: 'warning',
        position: 'center',
        background: '#000',
        showConfirmButton: true,
        confirmButtonText: 'Ver mis datos',
        showCancelButton: true,
        cancelButtonText: 'OK',
      }).then((result) => {
        if (result.value) {
          this.props.history.push('/perfil')
        }
      })
    }
    return (
      <Page
        className="show-detail"
        containerStyles={{ margin: "unset", width: "100%" }}
      >
        <div
          className="flex"
          style={heroContainerStyles(`url("${image ? image.url : undefined}")`)}
        >
          <div style={{ alignSelf: "flex-end", marginBottom: 20, padding: 10 }}>
            <h1 style={{ fontWeight: 500 }}>{title}</h1>
            {(type === "viajes-de-caza" || type === "concurso") && (
              <div
                style={{
                  display: "flex",
                  margin: "auto",
                  maxWidth: 500,
                  justifyContent: "center"
                }}
              >
                {participating ? (
                  <div
                    className="bg-blue-lightest border-t-4 border-blue rounded-b text-blue-darkest px-4 py-3 shadow-md"
                    role="alert"
                  >
                    <div className="flex">
                      <div className="py-1">
                        <svg
                          className="fill-current h-6 w-6 text-blue mr-4"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                        >
                          <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                        </svg>
                      </div>
                      <div>
                        <p className="font-bold">
                          {errorResponse
                            ? "¡Ya estabas apuntado!"
                            : "¡Apuntado!"}
                        </p>
                        <p className="text-sm">
                          Pronto nos pondremos en contacto contigo, por favor,
                          ten tu información de contacto actualizada en el
                          perfil
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <CTA
                    title="¡Me Apunto!"
                    onClick={this.addActivityParticipant}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <DescriptionContainer>
          <p dangerouslySetInnerHTML={{ __html: description }} />
          <div className="flex flex-col lg:flex-row mt-8 items-center">
            {modality
              ? this.renderContest({
                  title: "Modalidad",
                  description: modality,
                  image: modalityImage ? modalityImage.url : undefined
                })
              : null}
            {program
              ? this.renderContest({
                  title: "Programa",
                  description: program,
                  image: programImage ? programImage.url : undefined
                })
              : null}
            {datesPriceSeats
              ? this.renderContest({
                  title: "Fechas, Precio y Plazas",
                  description: datesPriceSeats,
                  image: datesPriceSeatsImage
                    ? datesPriceSeatsImage.url
                    : undefined
                })
              : null}
          </div>
        </DescriptionContainer>
      </Page>
    );
  }

  renderContest = ({ title, description, image }) => (
    <Contest
      screen="w-full lg:w-1/3"
      title={title}
      description={description}
      image={image}
      noCTA
    />
  );
}

const heroContainerStyles = (
  image = "https://images.unsplash.com/photo-1521651201144-634f700b36ef?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=11e83d71b84aec7e15917901c244d434&auto=format&fit=crop&w=1950&q=80"
) => ({
  paddingTop: 100,
  height: "80%",
  justifyContent: "center",
  backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.8)), ${image}`,
  backgroundSize: "cover",
  backgroundPosition: "50% 0"
});

export default ClubDetail;
