import React, { Component } from "react";
import { Page } from "components";
import request from "lib/request";

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      actualStep: 1,
      totalSteps: 2,
      email: "",
      password: "",
      confirmPassword: "",
      // coupon: "",
      clubId: props.match.params.clubId,
      submitting: false,
      submitResponse: null
    };
  }

  nextStep() {
    if (this.state.actualStep < this.state.totalSteps) {
      this.setState({
        actualStep: this.state.actualStep + 1
      });
    }
  }

  register = event => {
    this.setState({
      submitting: true
    });
    event.preventDefault();
    this.registerRequest = request("register", {
      method: "POST",
      body: JSON.stringify({
        email: this.state.email,
        password: this.state.password,
        clubId: parseInt(this.state.clubId),
        // coupon: this.state.coupon
      })
    });

    this.registerRequest.promise
      .then(response => {
        this.setState({
          submitting: false
        });

        if (!response.error) {
          this.nextStep();
        } else {
          let message = "";
          switch (response.error.status) {
            case 201:
              this.nextStep();
              break;
            case 400:
              message = response.body.message;
              break;
            case 401:
              message = "No se ha podido registrar el email";
              break;
            case 500:
              message = "Error desconocido en el servidor";
              break;
            default:
              message = `Error desconocido ${response.error.status}`;
          }
          this.setState({
            submitResponse: {
              type: "error",
              message
            }
          });
        }
      })
      .catch(error => {
        console.error(error);
        this.setState({
          submitting: false,
          submitResponse: {
            type: "error",
            message:
              "Error en la comunicación con el servidor. Vuelva a intentarlo en unos minutos."
          }
        });
      });
  };

  componentWillUnmount() {
    if (this.registerRequest) this.registerRequest.cancel();
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      submitResponse: null
    });
  };

  isSubmitButtonDisabled = () => {
    return (
      this.state.email === "" ||
      this.state.password === "" ||
      this.state.confirmPassword === "" ||
      this.state.password !== this.state.confirmPassword
    );
  };

  render() {
    let actualStep;

    //STEP {this.state.actualStep}/{this.state.totalSteps}

    switch (this.state.actualStep) {
      case 1:
        actualStep = (
          <div className="flex flex-wrap register-step register-step-1 mb-8">
            <div className="w-1/2 ml-auto pr-4">
              <p className="mb-4 font-lato">Datos de acceso</p>
            </div>
            <div className="w-1/2 mr-auto pl-4">
              <div className="mb-8">
                <label className="block mb-2 leading-tight text-xs">
                  Correo electrónico
                </label>
                <input
                  type="email"
                  name="email"
                  className="mb-4 leading-normal p-2 rounded"
                  autoComplete="email"
                  onChange={event => this.handleInputChange(event)}
                />
                <label className="block mb-2 leading-tight text-xs">
                  Contraseña (minimo 8 carácteres)
                </label>
                <input
                  type="password"
                  name="password"
                  className="mb-4 leading-normal p-2 rounded"
                  autoComplete="new-password"
                  onChange={event => this.handleInputChange(event)}
                />
                <label className="block mb-2 leading-tight text-xs">
                  Confirmar Contraseña
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  className="mb-4 leading-normal p-2 rounded"
                  autoComplete="confirm-password"
                  onChange={event => this.handleInputChange(event)}
                />
                {/*<label className="block mb-2 leading-tight text-xs">*/}
                {/*  Cupón (opcional)*/}
                {/*</label>*/}
                {/*<input*/}
                {/*  type="text"*/}
                {/*  name="coupon"*/}
                {/*  className="mb-4 leading-normal p-2 rounded"*/}
                {/*  onChange={event => this.handleInputChange(event)}*/}
                {/*/>*/}
                <button
                  disabled={this.isSubmitButtonDisabled()}
                  className={`${
                    this.isSubmitButtonDisabled()
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  } uppercase py-3 mt-4 font-medium bg-primary-hover font-bold py-2 px-4 rounded text-sm`}
                  onClick={this.register}
                >
                  {this.state.submitting ? "Registrando ..." : "Registrarse"}
                </button>
              </div>
              {this.state.submitResponse &&
              this.state.submitResponse.message ? (
                <div
                  className={`login-response login-response-type-${
                    this.state.submitResponse.type
                  } text-center`}
                >
                  {this.state.submitResponse.message}
                </div>
              ) : null}
            </div>
          </div>
        );
        break;
      case 2:
        actualStep = (
          <div className="flex flex-wrap register-step register-step-2 mb-8">
            <div className="w-1/2 ml-auto pr-4">
              <p className="mb-4 font-lato">Bienvenido/a a My Club TV</p>
              <p className="mb-4 text-sm font-lato">
                Suscríbete a My Club TV y disfruta de estrenos exclusivos y
                mucho más:
              </p>
            </div>
            <div className="w-1/2 mr-auto pl-4">
              <p className="mb-4">
                Hemos recibido correctamente la solicitud de registro.
              </p>
              <p>
                En breve recibirás un email de confirmación en el correo
                electrónico que nos ha proporcionado.
              </p>
            </div>
          </div>
        );
        break;
      default:
        actualStep = null;
    }

    return (
      <Page
        showMenus={false}
        showProfile={false}
        className="page-container public-page register-page"
      >
        <div className="page-content">
          <h1 className="text-2xl mb-4 font-medium">Registro en My Club TV</h1>
          {actualStep}
        </div>
      </Page>
    );
  }
}

export default Register;
