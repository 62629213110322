class Auth {
  init(callbackForAuthChanges) {
    this._isAuthenticated = false;
    this.callbackForAuthChanges = callbackForAuthChanges;
  }

  set isAuthenticated(isAuthenticated) {
    this._isAuthenticated = isAuthenticated;
    typeof this.callbackForAuthChanges === "function" &&
    this.callbackForAuthChanges(this.isAuthenticated);
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  set authToken(authToken) {
    localStorage.setItem("authToken", authToken);
    this.isAuthenticated = true;
  }

  get authToken() {
    return localStorage.getItem("authToken");
  }

  signout() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("strapiKey");
    localStorage.removeItem("stripeKey");
    localStorage.removeItem("clubId");
    this.isAuthenticated = false;
  }
}

export default new Auth();
