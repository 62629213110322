import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {
  disabledRoutes = ["/videoclub/serie/"];

  shouldTheRouteScrollToTop = pathname =>
    this.disabledRoutes.filter(disabledRoute =>
      pathname.includes(disabledRoute)
    ).length === 0;

  componentDidUpdate(prevProps) {
    if (
      this.props.location !== prevProps.location &&
      this.shouldTheRouteScrollToTop(this.props.location.pathname)
    ) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(ScrollToTop);
