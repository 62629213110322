import React, { Component } from "react";
import "./loading.css"

const REACT_APP_FRONTEND=process.env.REACT_APP_FRONTEND;

class Loading extends Component {
  render () {
    return (
      this.props.show && (<div className="text-center flex flex-col justify-center fixed pin-t pin-b pin-l pin-r w-screen h-screen z-2000" style={{ backgroundColor: 'rgb(0,0,0)', zIndex: 10000, alignItems: "center" }}>
        <h2 className="text-center text-2xl font-medium mb-10">Cargando...</h2>
        <img className="preloader" width={200} height={200} src={require("../env/img/" + (REACT_APP_FRONTEND || "brand") + "/loading.svg")} alt=""/>
      </div>)
    );
  }
}

export default Loading;
