import React, { Component } from "react";
import { Page } from "../components";
import request from "../lib/request";
import { getUserInfo } from "../lib/utils";
import Swal from 'sweetalert2'
import PaymentMethods from "../components/PaymentMethods";


const intervals = {
  DAY: "día",
  MONTH: "mes",
  YEAR: "año"
};

class Subscribe extends Component {
  constructor(props) {
    super(props)
    const coupon = this.props.match.params.couponCode;
    this.state = {
      submitResponse: null,
      submitting: false,
      selected_plan: 0,
      plans: null,
      user: null,
      doneSubscription: false,
      coupon,
      numMonths: null,
      discount: null,
      cardSelected: null,
    };
    this.fetchUserData();
    this.fetchSubscriptionPlans();
    this.fetchActualSubscription();
    if (coupon) this.checkCoupon(coupon);
  }

  userId = getUserInfo().userId;

  subscribeToPlan = event => {
    event.preventDefault();
    this.setState({
      ...this.state,
      submitting: true,
    });

    const selectedPlan = this.state.plans[this.state.selected_plan];

    if (selectedPlan) {
      const planId = this.state.plans[this.state.selected_plan].id;

      this.doSubscribeRequest = request(
        `subscriptions/users?club=${localStorage.getItem("clubId")}`,
        {
          method: "POST",
          body: JSON.stringify({
            coupon: this.state.coupon,
            paymentMethodId: this.state.cardSelected.cardId,
            planId: planId
          })
        }
      );

      this.doSubscribeRequest.promise.then(response => {
        if (!response.error) {
          this.setState({
            submitResponse: {
              type: "info",
              message: "Suscripción realizada con éxito"
            },
            doneSubscription: true
          });

          setTimeout(() => {
            this.props.history.push("/perfil/estado-suscripcion");
          }, 1000);
        } else {
          this.setState({
            submitResponse: {
              type: "error",
              message: `Status Error`
            },
            submitting: false,
          });
        }
      });
    }
  };

  componentWillUnmount() {
    if (this.subscriptionPlansRequest) this.subscriptionPlansRequest.cancel();
    if (this.accountRequest) this.accountRequest.cancel();
    if (this.doSubscribeRequest) this.doSubscribeRequest.cancel();
  }

  fetchUserData() {
    this.accountRequest = request(`users/${this.userId}`);
    this.accountRequest.promise.then(data =>
      this.setState({
        user: data
      })
    );
  }

  fetchSubscriptionPlans() {
    if (localStorage.getItem("clubId")) {
      this.subscriptionPlansRequest = request(`subscriptions/plans?club=${localStorage.getItem("clubId")}`);
      this.subscriptionPlansRequest.promise.then(data => {
        this.setState({
          plans: data.planList,
          selected_plan: 0
        });
      });
    }
  }


  fetchActualSubscription() {
    this.actualSubscriptionRequest = request(`users/${this.userId}/subscription`);
    this.actualSubscriptionRequest.promise.then(data => {
      this.setState({ subscriptionList: data.subscriptionList, subActive: data.active })
    });
  }

  checkCoupon() {
    if (this.state.coupon !== 'PROMOTV') {
      this.checkCoupon = request(`coupons/check-coupon`,
        {
          method: "POST",
          body: JSON.stringify({
            userId: this.userId,
            couponCode: this.state.coupon,
            forWebSubscription: true
          })
        });
      this.checkCoupon.promise.then(response => {
        if (!response.error && response.redeemable) {
          this.setState({
            numMonths: response.numMonths,
            discount: response.percentageOff
          });
        } else {
          this.setState({
            coupon: null,
            redeemable: false
          })
        }
      });
    }
  }

  getCouponDuration() {
    const { numMonths, plans, selected_plan } = this.state;
    if (plans[selected_plan].interval === 'YEAR') {
      const years = Math.ceil(numMonths / 12)
      return years > 1 ? `los ${years} primeros años` : `el primer año`
    } else {
      return `${numMonths} ${numMonths > 1 ? 'meses' : 'mes'}`
    }
  }

  getSubscriptionPrice(plan) {
    const { plans, selected_plan, discount } = this.state;
    const selectedPlanPrice = plan ? plan.price : plans[selected_plan].price;
    const dsct = discount ? (100 - discount) / 100 : 1;
    return (selectedPlanPrice * dsct).toFixed(2)
  }

  handleInputChange(self, event) {
    const target = event.target;
    let value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    switch (name) {
      case "selected_plan":
        value = parseInt(value);
        break;
      default:
    }

    self.setState({
      [name]: value,
      submitResponse: null
    });
  }

  handleToken = token => {
    this.addCardRequest = request("subscriptions/users/cards", {
      method: "POST",
      body: JSON.stringify({
        token
      })
    });

    this.addCardRequest.promise.then(response => {
      if (response.error) {
        this.setState({
          submitResponse: {
            type: "error",
            message:
              "No se pudo registrar el token de la tarjeta en el servidor"
          }
        });
      } else {
        this.fetchUserStripeCards();
      }
    });
  };


  render() {
    let plans;
    console.log(this.state.selected_plan)

    // if (this.state.subscriptionList && this.state.plans && this.state.subActive) {
    //   if (this.state.subscriptionList.length > 1 || !(this.state.plans.some(plan => plan.id === this.state.subscriptionList[0].plan.id) && this.state.coupon)) {
    //     if (this.state.redeemable != null && !this.state.redeemable) {
    //       Swal.fire({
    //         title: 'Cupón No Válido',
    //         text: 'Cupón no válido o no compatible con tu suscripción',
    //         type: 'error',
    //         position: 'center',
    //
    //         showConfirmButton: true,
    //         confirmButtonText: 'Gestionar suscripción',
    //         showCancelButton: false
    //       }).then(() => {
    //         this.props.history.push('/perfil/estado-suscripcion')
    //       })
    //     } else {
    //       Swal.fire({
    //         title: 'Suscripción Activa',
    //         text: 'Ya tienes una suscripción activa.',
    //         type: 'error',
    //         position: 'center',
    //
    //         showConfirmButton: true,
    //         confirmButtonText: 'Gestionar suscripción',
    //         showCancelButton: false
    //       }).then(() => {
    //         this.props.history.push('/perfil/estado-suscripcion')
    //       })
    //     }
    //     return (<Page className="page-container public-page subscription-page"></Page>)
    //   } else if (this.state.coupon && this.state.numMonths) {
    //     Swal.fire({
    //       title: 'Cupón Válido Para Tu Suscripción',
    //       text: `Se aplicará un descuento del ${this.state.discount}% (${this.getSubscriptionPrice()}€)
    //         durante ${this.getCouponDuration()}.`,
    //       type: 'warning',
    //       position: 'center',
    //
    //       showConfirmButton: true,
    //       confirmButtonText: 'Activar Cupón',
    //       showCancelButton: true,
    //       cancelButtonText: 'En otro momento',
    //       preConfirm: () => {
    //
    //         const couponRequest = request(
    //           'coupons/redeem', {
    //             method: "POST",
    //             body: JSON.stringify({
    //               userId: this.userId,
    //               couponCode: this.state.coupon,
    //               forWebSubscription: true
    //             })
    //           }
    //         );
    //
    //         return couponRequest.promise
    //           .then(response => {
    //             if (!response.success) {
    //               return {
    //                 response: 'error',
    //                 msg: response.body && response.body.message ? response.body.message : 'Cupon no válido'
    //               };
    //             }
    //             return {
    //               response: 'success'
    //             };
    //           }).catch(err => {
    //             return {
    //               response: 'error'
    //             }
    //           })
    //       },
    //       allowOutsideClick: () => !Swal.isLoading()
    //     }).then((value) => {
    //       if (value.value && value.value.response === 'success') {
    //         Swal.fire({
    //           position: 'center',
    //           type: 'success',
    //
    //           title: 'Cupón Activado',
    //           showConfirmButton: false,
    //           timer: 1500
    //         }).then(() => this.props.history.push('/perfil/estado-suscripcion'))
    //       } else if (value.value && value.value.response === 'error') {
    //         Swal.fire({
    //           position: 'center',
    //           type: 'error',
    //
    //           title: 'El Cupón No Pudo Ser Activado',
    //           showConfirmButton: false,
    //           timer: 1500
    //         }).then(() => this.props.history.push('/perfil/estado-suscripcion'))
    //       } else {
    //         this.props.history.push('/perfil/estado-suscripcion')
    //       }
    //     })
    //     return (<Page className="page-container public-page subscription-page"></Page>)
    //   }
    // }

    if (this.state.plans && Array.isArray(this.state.plans) && this.state.plans.length <= 0) {
      Swal.fire({
        title: 'No existen planes disponibles ',
        text: 'Lo sentimos, actualmente no existen planes disponibles',
        type: 'error',
        position: 'center',

        showConfirmButton: true,
        confirmButtonText: 'Gestionar suscripción',
        showCancelButton: false
      }).then(() => {
        this.props.history.push('/perfil/estado-suscripcion')
      })
      return (<Page className="page-container public-page subscription-page"/>)
    }

    if (this.state.subscriptionList && Array.isArray(this.state.subscriptionList) && this.state.subscriptionList.filter(({ plan }) => plan.price > 0)[0]) {
      Swal.fire({
        title: 'Ya dispones de un plan de pago',
        text: 'Lo sentimos, actualmente dispones de un plan de pago. Si quiere modificar su suscripción cancele su anterior plan y suscribete a uno nuevo',
        type: 'error',
        position: 'center',

        showConfirmButton: true,
        confirmButtonText: 'Gestionar suscripción',
        showCancelButton: false
      }).then(() => {
        this.props.history.push('/perfil/estado-suscripcion')
      })
      return (<Page className="page-container public-page subscription-page"/>)
    }


    if (this.state.plans) {
      plans = this.state.plans.map((plan, index) => {
        return (
          <option value={index} key={index}>
            {plan.name} ({this.getSubscriptionPrice(plan)} &euro;/
            {intervals[plan.interval]})
          </option>
        );
      });
    } else {
      plans = null;
    }

    let username;

    if (this.state.user) {
      username = `${this.state.user.firstName} ${
        this.state.user.lastName
      }`.trim();

      if (!username) {
        username = this.state.user.email;
      }
    } else {
      username = null;
    }

    const setPaymentMethod = (cardSelected) => {
      this.setState({ ...this.state, cardSelected })
    };

    return (
      <Page className="page-container public-page subscription-page">
        <div className="page-content">
          <h4 className="mb-4">Subscribir un Plan</h4>
          {Array.isArray(this.state.plans) && this.state.plans.length && (
            <form
              onSubmit={this.subscribeToPlan}
              className="flex flex-wrap register-step register-step-2 mb-4"
              autoComplete="on"
            >
              <div className="w-1 mr-auto">
                <label className="block mb-2 leading-tight">
                  Plan de suscripción
                </label>
                <div className="relative mt-4 mb-4">
                  <select
                    name="selected_plan"
                    className="block appearance-none w-full bg-grey-lighter border border-grey-lighter py-2 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey text-sm"
                    required="required"
                    defaultValue={{
                      label:
                        typeof this.state.selected_plan === "number"
                          ? `${
                            this.state.plans[this.state.selected_plan]
                              ? this.getSubscriptionPrice()
                              : "---"
                          } &euro;/${
                            intervals[
                              this.state.plans[this.state.selected_plan]
                              ]
                          }`
                          : "Elige un plan...",
                      value: this.state.selected_plan
                    }}
                    onChange={event => this.handleInputChange(this, event)}
                  >
                    {plans}
                  </select>
                  {(Array.isArray(this.state.plans) && this.state.plans.length > 0) &&
                  <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                    Descripción: {this.state.plans[this.state.selected_plan].description}
                  </div>}
                  <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                    <svg
                      className="fill-current h-4 w-4"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                    </svg>
                  </div>
                </div>
                {this.state.coupon && this.state.numMonths && (
                  <div>
                    <p className="mt-4 mb-4" style={{ color: '#66BB6A' }}>
                      CUPÓN VÁLIDO: Al suscribirse, se aplicará un descuento del {this.state.discount}%
                      (antes: {this.state.plans[this.state.selected_plan] ? this.state.plans[this.state.selected_plan].price.toFixed(2) : '---'}€)
                      durante {this.getCouponDuration()}.
                    </p>
                  </div>
                )}
                {this.state.coupon && this.state.coupon === 'PROMOTV' && (
                  <div>
                    <p className="mt-4 mb-4" style={{ color: '#66BB6A' }}>
                      CUPÓN DE SORTEO: Al suscribirse entrará en el sorteo de una TV
                    </p>
                  </div>
                )}
                <div className="row py-5">
                  Seleccione su método de pago
                </div>
                <PaymentMethods newCard={true} select={this.state.cardSelected || {}} setSelect={setPaymentMethod}/>
                {this.state.cardSelected && (
                  <div className="flex items-center justify-between mt-6">
                    <button
                      disabled={this.state.submitting}
                      className="uppercase py-3 font-medium bg-blue hover:bg-blue-dark text-white font-bold py-2 px-4 rounded text-sm"
                      type="submit"
                    >
                      {this.state.sendingResetPassword
                        ? "Enviando ..."
                        : (this.state.submitting ? "Confirmando ..." : "Confirmar suscripción")}
                    </button>
                  </div>
                )}
              </div>
            </form>
          )}
          <div className="flex flex-wrap mt-4 mb-8 register-step">
            {this.state.submitResponse && this.state.submitResponse.message ? (
              <div
                className={`w-1 mr-auto login-response login-response-type-${
                  this.state.submitResponse.type
                } text-center`}
              >
                {this.state.submitResponse.message}
              </div>
            ) : null}
          </div>
        </div>
      </Page>
    );
  }
}

export default Subscribe;
