import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { CTA } from "./Components";
import { mediaQueryBreakPoint, navbarHeight } from "./responsive";

const Container = styled.div`
  padding-left: 16.5%;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    padding: 0 !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - ${navbarHeight}px) !important;
  }
`;

const Title = styled.h1`
  max-width: 900px;
  margin-bottom: 30px;
  color: white;
  line-height: 55px;
  font-family: Lato, system;
  font-weight: 300;
  font-size: 32pt;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    text-align: center;
    font-size: 26pt;
    line-height: normal;
  }
`;

export default ({ height = 520, slides = [], paddingTop = "125px" }) => {
  return (
    <Slider arrows={false} autoplay={true} autoplaySpeed={5000} dots={false}>
      {slides.map(
        ({ title, renderTitle = () => {}, subtitle, image }, index) => (
          <div key={index}>
            <Container
              style={{
                height,
                paddingTop,
                background: `linear-gradient(transparent, rgba(0, 0, 0, 0.5)), url("${image}")`,
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}
            >
              <Title>
                {title}
                {renderTitle()}
              </Title>
              <p style={{ fontSize: "15pt" }}>{subtitle}</p>
              <CTA />
            </Container>
          </div>
        )
      )}
    </Slider>
  );
};
