import React from "react";
import styled from "styled-components";
import { CTA } from "./Components";
import image from "./images/mockup.jpg";
import tabletImage from "./images/mockup-tablet.jpg";
import phoneImage from "./images/mockup-movil.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MediaQuery from "react-responsive";

const mediaQueryBreakPoint = 1350;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  
  height: 700px;
  padding-top: 60px;  
  padding-bottom: 60px;
  background-color: #f1f1f1;
  text-align: center;
  color: #333333;
  font-family: Lato;
  background: url("${image}") top center no-repeat #f2f1f1;
  background-size: cover;
  
  @media (max-width: ${mediaQueryBreakPoint}px) {
    flex-direction: column-reverse;      
    background: white;
    height: 840px;    
  }

  @media(max-width: 600px) {
    height: 930px; 
  }
`;

const Column = styled.div`
  width: 50%;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    width: 100%;
  }
`;

const ColumnWithText = styled(Column)`
  padding-left: 16.5%;
  padding-top: 20px;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    margin-top: 20px;
    padding-left: 0;
  }
`;

const ColumnWithBackground = styled.div`
  position: relative;
  flex: 0 0 50%;
  margin-left: 0;
  background: url("${tabletImage}") bottom center no-repeat #f2f1f1;
  background-size: cover;
  width: ${mediaQueryBreakPoint}px;

  @media(max-width: 600px) {
    background: url("${phoneImage}") bottom center #f2f1f1;
    background-size: cover;
  }
`;

const Title = styled.h2`
  font-size: 50px;
  font-weight: 600;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    text-align: center;
    font-size: 26pt;
    line-height: normal;
  }
`;

const Body = styled.div`
  margin: 50px auto;
  margin-left: 16.5%;
  margin-right: 16.5%;
  text-align: left;
  max-width: 500px;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-left: auto;
    margin-right: auto;

    text-align: center;
  }
`;

const Subtitle = styled.h3`
  font-size: 28px;
  margin-bottom: 15px;
  font-weight: 600;
  border-bottom: 2px solid #000;
  padding-bottom: 10px;
`;

const Description = styled.p`
  margin-top: 20px;
  margin-bottom: 15px;
  font-size: 16px;
  color: #777777;
  line-height: 1.54;
`;

const VideoPlay = styled.a`
  position: absolute;
  z-index: 80;
  display: block;
  left: 380px;
  top: 300px;
  border: none;
  padding: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer;

  animation: bombea 1s infinite;
  animation-direction: alternate-reverse;

  @keyframes bombea {
    from {
      -moz-transform: scale(1);
      -webkit-transform: scale(1);
      -o-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
    }

    to {
      -moz-transform: scale(0.7);
      -webkit-transform: scale(0.7);
      -o-transform: scale(0.7);
      -ms-transform: scale(0.7);
      transform: scale(0.7);
    }
  }

  @media (max-width: ${mediaQueryBreakPoint}px) {
    left: auto;
    top: 140px;
    right: 980px;
  }

  @media (max-width: 600px) {
    left: auto;
    top: 240px;
    right: 1090px;
  }
`;

export default ({ openModal = () => {} }) => {
  return (
    <Container>
      <ColumnWithText>
        <Title>RAZONES POR LAS QUE SUSCRIBIRTE</Title>
        <Body>
          <Subtitle>TODO EL ENTRENIMIENTO A TU ALCANCE</Subtitle>
          <Description>
            <span style={{ fontWeight: 600 }}>Primer mes gratis,</span> después
            desde 5.99€/mes (iva incluido). <br />
            Sin compromiso de alta. <br />
            Puedes cancelar cuando quieras.
          </Description>
          <CTA>PRUEBA UN MES GRATIS</CTA>
        </Body>
      </ColumnWithText>
      <MediaQuery query={`(min-width: ${mediaQueryBreakPoint}px)`}>
        <Column
          style={{ position: "relative", marginLeft: "auto", flex: "0 0 50%" }}
        >
          <VideoPlay onClick={openModal}>
            <FontAwesomeIcon icon="play" size="1x" />
          </VideoPlay>
        </Column>
      </MediaQuery>
      <MediaQuery query={`(max-width: ${mediaQueryBreakPoint}px)`}>
        <ColumnWithBackground>
          <VideoPlay onClick={openModal}>
            <FontAwesomeIcon icon="play" size="1x" />
          </VideoPlay>
        </ColumnWithBackground>
      </MediaQuery>
    </Container>
  );
};
