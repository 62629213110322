export function getUserInfo() {
  try {
    const token = localStorage.getItem("authToken");
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  } catch (e) {
    return { userId: undefined };
  }
}
