import React from "react";
import { withRouter } from "react-router-dom";
import styled from "styled-components";
import MaterialIcon from "material-icons-react";
import { VideoPreview } from "components";
import request from "lib/request";


const REACT_APP_FRONTEND = process.env.REACT_APP_FRONTEND

const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 978px;
  display: flex;
  flex-direction: column;
  z-index: 51;
  width: 100%;
  background-color: black;
`;

const HeaderContainer = styled.div`
  width: 100%;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 576px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  left: 50%;

  transform: translate(-50%);
  height: 77px;

  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  margin-right: auto;
`;

const SearchInputContainer = styled.div`
  display: flex;
  width: 100%;
`;

const CloseButton = styled.button`
  width: 2.75rem;
  height: 2.75rem;
  color: white;
  outline: none;
`;

const BodyContainer = styled.span`
  position: relative;
`;

const Body = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  z-index: 102;
  padding-top: 2rem;
  padding-bottom: 10rem;
`;

export const SearchResultsContainer = styled.div`
  width: 100vw;
  max-width: 80rem;
  position: relative;
  padding-left: 3rem;
  padding-right: 3rem;
  margin-left: auto;
  margin-right: auto;
`;

export const ResultsList = styled.ul`
  flex: 1 1 auto;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-items: flex-start;
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: 0;
  justify-content: flex-start;
  display: flex;
  height: auto;
  position: relative;
  overflow: hidden;
  backface-visibility: hidden;
  overflow-y: auto;
`;

export const ResultsListItem = styled.li`
  flex: 0 0 auto;
  overflow: visible;
  padding-right: 5px;
  padding-right: 0.3125rem;
  padding-left: 5px;
  padding-left: 0.3125rem;
  margin-bottom: 20px;
  margin-bottom: 1.25rem;
  display: block;
`;

const FirstText = styled.p`
  margin: 50px auto;
  font-size: 20pt;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  position: relative;
  top: 1px;
  -webkit-appearance: textfield;
  box-sizing: content-box;

  width: 100%;
  color: white;
  background: transparent;
  outline: none;
  font-weight: 200;
  font-size: 20pt;
`;

const Image = styled.img`
  max-width: 250px;
  max-height: 60px;
  padding-left: 30px;
  padding-right: 50px;

  @media (max-width: 900px) {
    display: none;
  }
`;
class Search extends React.Component {
  state = {
    search: "",
    searchResults: []
  };

  minCharacters = 2;
  searchInputRef = React.createRef();

  componentDidMount() {
    document.body.style.overflow = "hidden";
    this.searchInputRef.current.focus();
  }

  onSearch = ({ target: { value: search } }) => {
    if (search.length > this.minCharacters) {
      this.getProducts(search);
    } else {
      this.setState({
        search
      });
    }
  };

  getProducts = search => {
    this.getSearchRequest = request(`products/search?query=${search}`);
    this.getSearchRequest.promise.then(({ productList }) => {
      this.setState({ searchResults: productList, search: search });
    });
  };

  playVideo = video => {
    if (video.series) {
      this.props.history.push(`/videoclub/serie/${video.id}`);
    } else {
      this.props.history.push(`/videoclub/video/${video.id}`);
    }
  };

  componentWillUnmount() {
    document.body.style.overflow = null;
  }

  render() {
    const { onCloseButtonClick = () => {} } = this.props;
    const { search, searchResults } = this.state;
    const { minCharacters, onSearch, searchInputRef } = this;

    return (
      <Container>
        <HeaderContainer>
          <Header className="site-header">
            <Image src={require("../env/img/" + (REACT_APP_FRONTEND || "brand") + "/logo.png")} />
            <span className="ml-6 mr-6 flex">
              <MaterialIcon icon="search" color="white" size="medium" />
            </span>
            <SearchInputContainer>
              <span className="flex flex-grow">
                <SearchInput
                  type="text"
                  placeholder="Buscar..."
                  onChange={onSearch}
                  ref={searchInputRef}
                />
              </span>
              <span className="flex">
                <CloseButton onClick={onCloseButtonClick}>
                  <MaterialIcon icon="close" color="white" size="medium" />
                </CloseButton>
              </span>
            </SearchInputContainer>
          </Header>
        </HeaderContainer>
        <BodyContainer className="flex-grow">
          <Body>
            <SearchResultsContainer>
              <ResultsList className="list-reset">
                {search.length > minCharacters ? (
                  searchResults.length ? (
                    searchResults.map(
                      ({ id, title, thumbnail, series }, index) => (
                        <ResultsListItem
                          className="w-full sm:w-1/2 md:w-1/4"
                          key={index}
                        >
                          <VideoPreview
                            title={title}
                            image={thumbnail ? thumbnail.url : undefined}
                            onPlayButtonClick={() => {
                              this.playVideo({ id, series });
                            }}
                          />
                        </ResultsListItem>
                      )
                    )
                  ) : (
                    <FirstText>
                      Tu búsqueda no ha obtenido resultados. Por favor,
                      inténtalo de nuevo.
                    </FirstText>
                  )
                ) : (
                  <FirstText>
                    Introduce al menos {minCharacters} caracteres para realizar
                    la búsqueda.
                  </FirstText>
                )}
              </ResultsList>
            </SearchResultsContainer>
          </Body>
        </BodyContainer>
      </Container>
    );
  }
}

export default withRouter(Search);
