import React, { Component } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { Page, VideoCarousel, VideoPreview } from "components";
import request from "lib/request";

const Container = styled.div`
  padding-top: 0px;

  @media (max-width: 900px) {
    padding-top: 35px;
  }

  @media (max-width: 768px) {
    padding-top: 19px;
  }
`;

const Anchor = styled.a`
  text-decoration: none;
  color: white;

  &:hover {
    text-decoration: underline;
  }
`;

class Videoclub extends Component {
  state = {
    premieres: [],
    groups: [],
    loading: true,
    showSearchContent: false
  };

  invisibleProducts = ["default", "premieres"];
  isInvisibleProduct = key => this.invisibleProducts.indexOf(key) !== -1;

  componentDidMount() {
    this.fetchData();
  }

  fetchData() {
    this.dataRequest = request("products/home");

    this.dataRequest.promise
      .then(data => {
        const groups = Object.keys(data).reduce(
          (reducer, key) =>
            this.isInvisibleProduct(key)
              ? reducer
              : [
                ...reducer,
                ...(key === "categoryList" ? data[key] : [data[key]])
              ],
          []
        );


        const [, , ...rest] = groups;
        const theRest = rest.filter(res => !Array.isArray(res));
        const { recents } = data;
        recents.name = "Recientemente Añadidos";
        this.searchResults = groups;
        this.setState({
          groups: [recents, ...theRest],
          premieres: data.premieres.productList,
          loading: false
        });
      })
      .catch(error => {
        console.error(error);
      });
  }

  componentWillUnmount() {
    if (this.dataRequest) this.dataRequest.cancel();
  }

  playVideo = video => {
    if (video.series) {
      this.props.history.push(`/videoclub/serie/${video.id}`);
    } else {
      this.props.history.push(`/videoclub/video/${video.id}`);
    }
  };

  renderCarouselItem = video => {
    return (
      <VideoPreview
        {...video}
        title={video.title}
        image={video.thumbnail ? video.thumbnail.url : undefined}
        onPlayButtonClick={() => this.playVideo(video)}
      />
    )
  };

  render() {
    const { groups, premieres } = this.state;

    return (
      <Page
        showSearch={true}
        className="videoclub-page"
        isLoading={this.state.loading}
      >
        {!this.state.loading && (
          <div>
            <Slider
              arrows={false}
              autoplay={true}
              autoplaySpeed={5000}
              dots={false}
            >
              {premieres.map(({ id, title, thumbnail }, index) => (
                <div key={index}>
                  <Container>
                    <VideoPreview
                      onPlayButtonClick={({ video }) => this.playVideo(video)}
                      video={{ id }}
                      paddingBottom={"28.25%"}
                      image={thumbnail ? thumbnail.url : "https://images.unsplash.com/photo-1517836357463-d25dfeac3438?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80"}
                      imageMode="cover"
                      iconSize="3x"
                      title={title}
                      showTitleOnImage={true}
                    />
                  </Container>
                </div>
              ))}
            </Slider>
            <div className="video-carousels-wrapper">
              {groups
                .filter(({ productList }) => productList.length !== 0)
                .map(({ id, name, productList }, index) => {
                  return (
                    <VideoCarousel
                      key={index}
                      renderTitle={() =>
                        name !== "Recientemente Añadidos" ? (
                          <Anchor
                            className="on-hover-color-primary"
                            href="#"
                            onClick={() =>
                              this.props.history.push(
                                `/videoclub/categoria/${id}`
                              )
                            }
                          >
                            {name}
                          </Anchor>
                        ) : (
                          name
                        )
                      }
                      renderViewMore={() =>
                        name !== "Recientemente Añadidos" && (
                          <Anchor
                            className="on-hover-color-primary"
                            href="#"
                            style={{ marginRight: 50 }}
                            onClick={() =>
                              this.props.history.push(
                                `/videoclub/categoria/${id}`
                              )
                            }
                          >
                            Ver más
                          </Anchor>
                        )
                      }
                      items={productList}
                      renderItem={this.renderCarouselItem}
                      onTap={this.playVideo}
                      onClick={this.playVideo}
                    />
                  );
                })}
            </div>
          </div>
        )}
      </Page>
    );
  }
}

export default Videoclub;
