import React from "react";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { DateTime } from "luxon";
import { Page, ProgrammingList, Programming } from "components";
import { addFutureDates } from "lib/utils";
import request from "lib/request";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Container = styled.div`
  position: relative;
  margin-top: 50px;
  text-align: center;
  padding-bottom: 100px;
`;

const ProgrammingContainer = styled.div`
  padding: 2%;
  padding-top: 0;
  justify-content: center;
`;

const ProgrammingListContainer = styled.div`
  padding: 2%;
`;

const ProgrammingDates = styled.div`
  justify-content: space-between;
  margin: 15px auto;
  max-width: 600px;
`;

const ProgrammingDate = styled.p`
  padding: 10px;
  border-radius: 20px;
  font-family: "Roboto";
  font-size: 14pt;
  cursor: pointer;

  &.active,
  &:hover {
    background-color: #1d7aff;
  }
`;

const StyledAnchorLink = styled(AnchorLink)`
  text-decoration: none;
  color: white;
`;

class LiveProgramming extends React.Component {
  state = {
    activeDate: "Hoy"
  };

  channels = [
    {
      id: 2,
      title: "Iberalia 100% Pesca"
    },
    {
      id: 1,
      title: "Iberalia Original HD"
    },
    {
      id: 3,
      title: "Iberalia 100% Caza"
    }
  ];

  programmingDates = addFutureDates([{ name: "Hoy", value: new Date() }]);

  componentDidMount() {
    this.getProgrammingData({ channelId: this.props.channelId });
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.channelId && nextProps.channelId !== this.getChannelId()) {
      this.getProgrammingData({ channelId: nextProps.channelId });
    }

    return true;
  }

  componentWillUnmount() {
    if (this.programmingRequest) this.programmingRequest.cancel();
  }

  getChannelId = () =>
    this.props.match && this.props.match.params
      ? parseInt(this.props.match.params.channelId)
      : this.props.channelId;

  getProgrammingData = ({
    startDate = DateTime.local().startOf("day"),
    channelId = this.getChannelId()
  }) => {
    const endDate = this.formatDate(startDate.endOf("day"));
    this.programmingRequest = request(
      `channels/${channelId}/programming?startDate=${this.formatDate(
        startDate
      )}&endDate=${endDate}`
    );
    this.programmingRequest.promise.then(({ programmingList, channel }) => {
      this.props.onSubscriptionDataObtained(channel.video.subscriptionBlocked);
      this.setState({ programmingList});
    });
  };

  formatDate = date =>
    date.toFormat("yyyy-LL-dd") + "T" + date.toFormat("HH:mm:ss");

  handleProgrammingDateClick = ({ name, value }) => {
    this.setState({ activeDate: name });
    this.getProgrammingData({
      startDate: DateTime.fromJSDate(value).startOf("day")
    });
  };

  render() {
    const { isPage = true } = this.props;
    return isPage ? (
      <Page>{this.renderProgramming(isPage)}</Page>
    ) : (
      this.renderProgramming(isPage)
    );
  }

  renderProgramming = isPage => {
    const { activeDate, programmingList } = this.state;
    const channelId = this.getChannelId();

    return channelId ? (
      <Container>
        <h1 id={`programming-channel-${channelId}`}>Programación</h1>
        <ProgrammingDates className="flex flex-col sm:flex-row">
          {this.programmingDates.map((date, index) => (
            <StyledAnchorLink
              key={index}
              href={`#programming-channel-${channelId}`}
              offset={isPage ? 0 : -500}
            >
              <ProgrammingDate
                onClick={() => this.handleProgrammingDateClick(date)}
                className={`${date.name === activeDate ? "active" : ""}`}
              >
                {date.name}
              </ProgrammingDate>
            </StyledAnchorLink>
          ))}
        </ProgrammingDates>
        <ProgrammingContainer className="flex flex-col">
          <ProgrammingListContainer className="w-full">
            <ProgrammingList
              title={this.channels.find(({ id }) => channelId === id).title}
              items={programmingList}
              renderItem={({ name, time, category }) => (
                <Programming
                  title={name}
                  hour={time}
                  category={category.name}
                />
              )}
            />
          </ProgrammingListContainer>
        </ProgrammingContainer>
      </Container>
    ) : null;
  };
}

export default LiveProgramming;
