import React from "react";
import auth from "lib/Auth";

const Logout = ({ history }) => {
  auth.signout();
  history.push("/");
  return <div />;
};

export default Logout;
