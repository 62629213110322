import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "components";
import request from "lib/request";

class ResetPassword extends Component {
  state = {
    submitResponse: null,
    sendingResetPassword: false,
    password: "",
    repeat_password: "",
    email: "",
    unHashedToken: ""
  };

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    const email = params.get("email");
    this.setState({ email });
  }

  submitPasswordReset = event => {
    event.preventDefault();

    const { password, repeat_password, unHashedToken, email } = this.state;
    const isValid = unHashedToken && password && password === repeat_password;

    if (isValid) {
      this.setState({
        sendingResetPassword: true
      });

      this.resetPasswordRequest = request(`reset-password`, {
        method: "POST",
        body: JSON.stringify({
          email,
          password,
          unHashedToken
        })
      });

      this.resetPasswordRequest.promise.then(response => {
        this.setState({
          sendingResetPassword: false
        });

        if (!response || !response.error) {
          this.setState({
            submitResponse: {
              type: "info",
              message: "Contraseña restablecida correctamente."
            }
          });
        } else {
          let message = "";
          switch (response.error.status) {
            case 400:
            case 401:
              message = "El token no es válido";
              break;
            default:
              message = `Error desconocido ${response.error.status}`;
          }

          this.setState({
            submitResponse: {
              type: "error",
              message
            }
          });
        }
      });
    } else {
      this.setState({
        submitResponse: {
          type: "error",
          message: "No has introducido el código o las contraseñas no coinciden"
        }
      });
    }
  };

  componentWillUnmount() {
    if (this.resetPasswordRequest) {
      this.resetPasswordRequest.cancel();
    }
  }

  handleInputChange = event => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value,
      submitResponse: null
    });
  };

  render() {
    return (
      <Page
        showMenus={false}
        showProfile={false}
        className="page-container public-page reset-password-page"
      >
        <div className="page-content">
          <h4 className="mb-4">Restablecer contraseña</h4>
          <form
            onSubmit={this.submitPasswordReset}
            className="flex flex-wrap register-step register-step-2 mb-8"
            autoComplete="on"
          >
            <div className="w-1 mr-auto">
              <label className="block mb-2 leading-tight text-xs">Email</label>
              <input
                type="email"
                name="email"
                className="mb-4 leading-normal p-2 rounded"
                value={this.state.email}
                onChange={this.handleInputChange}
              />
              <label className="block mb-2 leading-tight text-xs">
                Introduzca código recibido
              </label>
              <input
                type="text"
                name="unHashedToken"
                className="mb-4 leading-normal p-2 rounded"
                value={this.state.unHashedToken}
                onChange={this.handleInputChange}
              />
              <label className="block mb-2 leading-tight text-xs">
                Nueva contraseña
              </label>
              <input
                type="password"
                name="password"
                autoComplete="new-password"
                className="mb-4 leading-normal p-2 rounded"
                value={this.state.password}
                onChange={this.handleInputChange}
              />
              <label className="block mb-2 leading-tight text-xs">
                Repita contraseña
              </label>
              <input
                type="password"
                name="repeat_password"
                autoComplete="new-password"
                className="mb-4 leading-normal p-2 rounded"
                value={this.state.repeat_password}
                onChange={this.handleInputChange}
              />
              <div className="flex items-center justify-between mt-2">
                <button
                  className="uppercase py-3 font-medium bg-primary-hover font-bold py-2 px-4 rounded text-sm"
                  type="submit"
                >
                  {this.state.sendingResetPassword
                    ? "Enviando ..."
                    : "Cambiar contraseña"}
                </button>
              </div>
            </div>
          </form>
          <div className="flex flex-wrap mb-8 register-step">
            {this.state.submitResponse && this.state.submitResponse.message ? (
              <div
                className={`w-1 mr-auto login-response login-response-type-${
                  this.state.submitResponse.type
                } text-center`}
              >
                {this.state.submitResponse.message}
                {this.state.submitResponse.type === "info" && (
                  <p>
                    <Link style={{ color: "white" }} to={"/login"}>
                      Iniciar Sesión
                    </Link>
                  </p>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </Page>
    );
  }
}

export default ResetPassword;
