import React from "react";
import styled from "styled-components";

const Container = styled.div`
  padding: 20px;
  border-radius: 10px;
  background-color: #474a56;
  font-family: Roboto;
`;

const Info = styled.p`
  color: #c0c0c0;
`;

const Title = styled.h2`
  font-size: 14pt;
  color: white;
`;

const Programming = ({ hour, category, title }) => {
  return (
    <Container>
      <Info>
        {hour} {category}
      </Info>
      <Title>{title}</Title>
    </Container>
  );
};

export default Programming;
