import React from "react";
import { FormField } from "ui";

class ChangePassword extends React.Component {
  state = {
    oldPasswordIsEmpty: false,
    doPasswordsMatch: true
  };

  onSubmit = event => {
    event.preventDefault();
    const values = this.formatElementsToObject([...event.target.elements]);
    const { oldPassword, newPassword, newPasswordConfirmation } = values;

    if (oldPassword === "") {
      this.setState({ oldPasswordIsEmpty: true });
    } else if (newPassword === newPasswordConfirmation) {
      this.setState({ doPasswordsMatch: true, oldPasswordIsEmpty: false });
      this.props.handleSubmit({
        values,
        form: "change-password"
      });
    } else {
      this.setState({ doPasswordsMatch: false, oldPasswordIsEmpty: false });
    }
  };

  formatElementsToObject = values => {
    return values.reduce(
      (reducer, { name, value }) => ({ ...reducer, [name]: value }),
      {}
    );
  };

  render() {
    const { doPasswordsMatch, oldPasswordIsEmpty } = this.state;
    return (
      <form onSubmit={this.onSubmit}>
        <FormField
          title="Contaseña Actual"
          name="oldPassword"
          type="password"
          error={oldPasswordIsEmpty}
          errorMessage="Por favor, introduce tu contraseña actual"
        />
        <FormField
          title="Nueva Contraseña"
          name="newPassword"
          type="password"
        />
        <FormField
          title="Confirmar Contraseña Nueva"
          name="newPasswordConfirmation"
          type="password"
          error={!doPasswordsMatch}
          errorMessage="Las contraseñas no coinciden. Por favor, inténtalo de nuevo"
        />
        <button
          className="bg-primary-hover font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          type="submit"
        >
          CAMBIAR CONTRASEÑA
        </button>
      </form>
    );
  }
}

export default ChangePassword;
