import React from "react";
import Scrollspy from "react-scrollspy";
import styled from "styled-components";
import AnchorLink from "react-anchor-link-smooth-scroll";

const ListItem = styled.li`
  padding: 15px 35px 15px 35px;
  white-space: "nowrap";

  &.active {
    background-color: #1d7aff;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
  }
`;

const StlyedAnchorLink = styled(AnchorLink)`
  text-decoration: none;
  color: white;
`;

const Sidebar = ({ items = [{ id: null, title: null }] }) => {
  return (
    <Scrollspy
      items={items.map(({ id }) => id)}
      className="list-reset"
      currentClassName="active"
    >
      {items.map(({ id, title }, index) => (
        <ListItem key={index}>
          <StlyedAnchorLink href={`#${id}`} offset={20}>
            {title}
          </StlyedAnchorLink>
        </ListItem>
      ))}
    </Scrollspy>
  );
};

export default Sidebar;
