import React from "react";
import styled from "styled-components";
import { mediaQueryBreakPoint } from "../responsive";

const StyledCTA = styled.a`
  display: inline-block;
  margin-top: 20px;
  background-color: #d44001;
  padding: 15px 50px;
  font-size: 22px;
  line-height: 1.5;
  border-radius: 50px;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
  color: white;

  transition: all 0.2s ease-in-out;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-in-out;
  transition-delay: 0s;

  @media (max-width: ${mediaQueryBreakPoint}px) {
    text-align: center;    
    font-size: 15px;
  }
`;

export default props => (
  <StyledCTA {...props} href={props.href || "/registro"}>
    {props.children || "PRUEBA UN MES GRATIS"}
  </StyledCTA>
);
