import React, { Component } from "react";
import { Page, VideoPreview, SeasonSelector, SeasonDetail } from "components";
import { Route } from "react-router-dom";
import request from "lib/request";
import Swal from 'sweetalert2'


class ShowDetail extends Component {
  state = {
    show: {
      seasonList: []
    }
  };

  componentDidMount() {
    this.getShowDetails(this.props.match.params.showId);
  }

  getShowDetails = showId => {
    this.getShowDetailsRequest = request(`products/${showId}`);
    this.getShowDetailsRequest.promise.then(show => this.setState({ show }));
  };

  componentWillUnmount() {
    if (this.getShowDetailsRequest) this.getShowDetailsRequest.cancel();
  }

  addSinopsis = seasons =>
    seasons.length ? [{ title: "Sinopsis" }, ...seasons] : seasons;

  transformSeasons = seasons =>
    seasons.map(season => {
      season.title = `Temporada ${season.num}`;
      return season;
    });

  handleSeasonClick = (season, index) => {
    if (index === 0) {
      this.props.history.push(
        `/videoclub/serie/${this.props.match.params.showId}`
      );
      this.activeSeason = 0;
    } else {
      this.props.history.push(
        `/videoclub/serie/${this.props.match.params.showId}/temporada/${
          season.num
        }`
      );
      this.activeSeason = index;
    }
  };

  goToPlayScreen = ({ event, video }) => {
    if (video.video.subscriptionBlocked) {
      Swal.fire({
        title: 'Suscripción Caducada',
        text: 'No tienes ninguna suscripción activa en este momento, subscribete ahora para poder ver contenidos como este.',
        type: 'warning',
        position: 'center',
        background: '#000',
        showConfirmButton: true,
        confirmButtonText: 'Gestionar suscripción',
        showCancelButton: true,
        cancelButtonText: 'OK',
      }).then((result) => {
        if (result.value) {
          this.props.history.push('/perfil/estado-suscripcion')
        }
      })
    } else {
      this.props.history.push(`/player/${this.props.match.params.showId}/${video.id}`);
    }
  };

  SeasonDetailWithProps = props => {
    this.activeSeason = parseInt(props.match.params.seasonId);
    return <SeasonDetail show={this.state.show} {...props} />;
  };

  render() {
    const {
      title,
      description,
      webImage = "https://images.unsplash.com/photo-1521651201144-634f700b36ef?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=11e83d71b84aec7e15917901c244d434&auto=format&fit=crop&w=1950&q=80",
      seasonList = []
    } = this.state.show;

    return (
      <Page
        className="show-detail"
        containerStyles={{ margin: "unset", width: "100%" }}
      >
        <div
          className="flex"
          style={heroContainerStyles(`url("${webImage.url}")`)}
        >
          <div style={{ alignSelf: "flex-end" }}>
            <h1>{title}</h1>
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <SeasonSelector
              seasons={this.addSinopsis(this.transformSeasons(seasonList))}
              activeIndex={this.activeSeason ? this.activeSeason : 0}
              onSeasonClick={this.handleSeasonClick}
            />
          </div>
        </div>
        <div className="flex">
          <div className="w-full">
            <Route
              exact
              path="/videoclub/serie/:showId"
              render={() => this.renderSinopsis({ description, seasonList })}
            />
            <Route
              exact
              path="/videoclub/serie/:showId/temporada/:seasonId"
              component={this.SeasonDetailWithProps}
            />
          </div>
        </div>
      </Page>
    );
  }

  renderSinopsis = ({ description, seasonList }) => (
    <div className="show-info-container">
      <h2 style={{ fontWeight: 100 }}>COMENZAR A VER</h2>
      <div className="flex flex-wrap" style={previewContainerStyles}>
        <div className="w-full sm:w-1/2 preview">
          {seasonList &&
          seasonList[0] &&
          seasonList[0].episodeList &&
          seasonList[0].episodeList[0] ? (
            <React.Fragment>
              <VideoPreview
                iconSize="3x"
                image={
                  seasonList[0].episodeList[0].image
                    ? seasonList[0].episodeList[0].image.url
                    : undefined
                }
                video={seasonList[0].episodeList[0]}
                onPlayButtonClick={this.goToPlayScreen}
              />
              <p>{seasonList[0].episodeList[0].title}</p>
              <p>T1 / E1</p>
            </React.Fragment>
          ) : (
            ""
          )}
        </div>
        <div className="w-full sm:w-1/2 sinopsis">
          <p
            style={{ lineHeight: 1.5 }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  );
}

const heroContainerStyles = image => ({
  paddingTop: 100,
  height: "100%",
  justifyContent: "center",
  backgroundImage: `linear-gradient(transparent, rgba(0, 0, 0, 0.8)), ${image}`,
  backgroundSize: "cover",
  backgroundPosition: "50% 0"
});

const previewContainerStyles = {
  marginTop: 10
};

export default ShowDetail;
