import React from "react";
import { limitText } from "lib/utils";
import { CTA } from "ui";

const News = ({
  title,
  image = "https://images.unsplash.com/photo-1521651201144-634f700b36ef?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=11e83d71b84aec7e15917901c244d434&auto=format&fit=crop&w=1950&q=80",
  description,
  url = "#"
}) => {
  return (
    <div
      className={`rounded overflow-hidden shadow-lg ml-5 mr-5 mb-6`}
      style={{ backgroundColor: "#25262E" }}
    >
      <img className="w-full" src={image} alt={title} />
      <div className="px-6 py-4">
        <div className="font-bold text-xl mb-2">{title}</div> 
        <p
          className="text-white text-base"
          dangerouslySetInnerHTML={{ __html: limitText(description) }}
        />
        <CTA title="Seguir leyendo"  url={url} className="mt-5 bg-primary-hover" />
      </div>
    </div>
  );
};

export default News;
