import React, { Component } from "react";
import styled from "styled-components";
import { Page, New } from "components";
import request from "lib/request";

const Container = styled.div`
  @media (min-width: 200px) {
    margin-left: 10px;
  }

  p {
    line-height: 2;
  }
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
`;

class Club extends Component {
  state = {
    news: []
  };

  sidebarItems = [
    /*{
      id: "draws",
      title: "Sorteos",
      requestURL: "draws",
      responseKey: "drawList",
      render: ({ id, title, description, image }, index) => (
        <Contest
          key={index}
          image={image ? image.url : undefined}
          title={title}
          description={description}
          url={`/club/sorteo/${id}`}
        />
      )
    },*/
    {
      id: "news",
      title: "Noticias",
      requestURL: "news",
      responseKey: "newsList",
      render: ({ id, title, description, image }, index) => (
        <New
          key={index}
          url={`/noticias/${id}`}
          title={title}
          description={description}
          image={image ? image.url : undefined}
        />
      )
    }
  ];

  componentDidMount() {
    this.sidebarItems.map(({ id, requestURL, responseKey }) => {
      if (requestURL && responseKey) {
        this[id + "Request"] = request(requestURL);
        return this[id + "Request"].promise.then(response =>
          response.error
            ? this.setState({ [id]: [] })
            : this.setState({ [id]: response[responseKey] })
        );
      }

      return null;
    });
  }

  componentWillUnmount() {
    this.sidebarItems.map(({ id }) =>
      this[id + "Request"] ? this[id + "Request"].cancel() : null
    );
  }

  render() {
    const { sidebarItems } = this;
    return (
      <Page
        className="club-page"
        containerStyles={{
          marginLeft: 0,
          marginRight: 10,
          paddingBottom: 200
        }}
      >
        <div>
          <Container>
            {sidebarItems.map(({ id, title, render }, index) => (
              <div key={index} style={{width: "100%"}}>
                <h2 style={{ textAlign: "center" }} id={id} className="mb-16 mt-12">
                  {title}
                </h2>
                {this.state[id].length ? (
                  <ItemsContainer>
                    {this.state[id].map((item, index) => (
                      <div key={index} className="w-full md:w-1/2">
                        {render(item, index)}
                      </div>
                    ))}
                  </ItemsContainer>
                ) : (
                  <p>Ahora mismo no tenemos {title.toLowerCase()}</p>
                )}
              </div>
            ))}
          </Container>
        </div>
      </Page>
    );
  }
}

export default Club;
