import React from "react";
import styled from "styled-components";
import { Route } from "react-router-dom";
import request from "../lib/request";
import MediaQuery from "react-responsive";
import { getUserInfo } from "../lib/utils";
import { Page, PersonalInformation, ChangePassword, Logout } from "../components";
import { DateTime } from "luxon";
// import Coupon from '../components/Coupon';
import PaymentMethods from "../components/PaymentMethods";
import moment from "moment"

const intervals = {
  DAY: 'día',
  MONTH: 'mes',
  YEAR: 'año'
}

const AccountContainer = styled.div`
  padding-left: 3rem;
  padding-right: 3rem;
  justify-content: center;
`;

const Sidebar = styled.ul``;

const SidebarItem = styled.li`
  margin-bottom: 20px;
`;

const Link = styled.a`
  text-decoration: none;
  color: white;
  font-size: 17pt;

  &.active {
    font-weight: bold;
  }
`;

class Profile extends React.Component {
  constructor(props) {
    super(props)
    this.fetchActualSubscription = this.fetchActualSubscription.bind(this)
  }

  state = {
    address: null,
    birthdate: null,
    city: null,
    country: null,
    email: null,
    firstName: null,
    lastName: null,
    phone: null,
    postalCode: null,
    subscriptionList: null,
    subActive: null,
  };

  pages = [
    {
      id: 1,
      name: "Información personal",
      route: "/perfil",
      render: () => (
        <div className="w-full md:w-1/3">
          <PersonalInformation
            handleSubmit={this.handleFormSubmit}
            defaultValues={this.state}
          />
        </div>
      )
    },
    {
      id: 2,
      name: "Cambiar contraseña",
      route: "/perfil/cambiar-contrasena",
      render: () => (
        <div className="w-full md:w-1/3">
          <ChangePassword handleSubmit={this.handleFormSubmit}/>
        </div>
      )
    },
    {
      id: 3,
      name: "Estado suscripción",
      route: "/perfil/estado-suscripcion",
      render: () => {
        const activeSubscriptionsList = Array.isArray(this.state.subscriptionList) ? this.state.subscriptionList.filter(s => s.plan.active) : null;
        const subscriptionActive = this.state.subActive;
        // const nextPayment = this.state.nextPayment
        // const activeSubscriptionsList = [
        //   {
        //     "endDate": "2018-12-31T23:59:59+0100",
        //     "startDate": "2018-12-01T00:00:00+0100",
        //     "plan": {
        //       "active": true,
        //       "name": "Suscripción mensual",
        //       "interval": "MONTH",
        //       "price": 5.99,
        //       "extendable": false
        //     }
        //   }
        // ]

        let activeSubscriptions = null;
        let paidSubscription = false;
        if (subscriptionActive !== null) {
          if (subscriptionActive) {
            activeSubscriptions = <React.Fragment>
              {
                activeSubscriptionsList.map((s, index) => {
                  const startDate = DateTime.fromISO(s.startDate).toLocaleString(DateTime.DATE_FULL);
                  const endDate = s.endDate ? moment(s.endDate).subtract(1, "day").format("MMMM DD, YYYY") : "-";
                  paidSubscription = paidSubscription || !s.endDate;
                  return (
                    <div className="subscription-plan mt-10" key={index}>
                      <h3 className="mb-2 leading-loose text-2xl">{s.plan.name}</h3>
                      <h4
                        className="mt-2 leading-loose">{s.plan.price.toFixed(2)} &euro;/{intervals[s.plan.interval]}</h4>
                      <p className="mt-4">Desde <span className="font-bold">{startDate}</span></p>
                      <p className="mt-2">Hasta <span className="font-bold">{endDate}</span></p>
                      <p className="mt-4 text-xs">Ambas fechas incluidas</p>
                      
                      {s.endDate && (
                        <p className="mt-4 text-sm">La suscripción no se renovará al finalizar el periodo.</p>)}
                      {s.billingDate && !s.endDate && (
                        <p className="mt-4 text-sm leading-normal">La suscripción se renovará automáticamente si no se
                          cancela expresamente la suscripción.</p>)}
                      {s.plan.stripeId && !s.endDate && (<button
                        className="mt-4 uppercase py-3 font-medium bg-blue hover:bg-blue-dark text-white font-bold py-2 px-2 rounded text-sm"
                        onClick={() => this.cancelSubscription(s.id)}>
                        Cancelar suscripción
                      </button>)}
                    </div>
                  );
                })
              }
              {(Array.isArray(activeSubscriptionsList) && !activeSubscriptionsList.filter(({ plan }) => plan.price > 0)[0]) &&
              <button
                className="uppercase py-3 font-medium bg-blue hover:bg-blue-dark text-white font-bold py-2 px-2 rounded text-sm my-3"
                onClick={this.goToSubscription}>
                Suscribirse a un nuevo plan
              </button>
              }
            </React.Fragment>
          } else {
            activeSubscriptions = (
              <div>
                <p className="mb-6">No hay suscripciónes activas</p>
                <button
                  className="uppercase py-3 font-medium bg-blue hover:bg-blue-dark text-white font-bold py-2 px-2 rounded text-sm"
                  onClick={this.goToSubscription}>
                  Suscribirse
                </button>
              </div>
            );
          }
        }

        return (
          <div className="w-full md:w-1/3">
            {subscriptionActive === null && (<p>Obteniendo suscripciones activas</p>)}
            {subscriptionActive !== null && (<div className="subscription-plans">
              {activeSubscriptions}
            </div>)}
            {/*<Coupon disabled={paidSubscription} history={this.props.history}*/}
            {/*        onCouponRedeemed={this.fetchActualSubscription}/>*/}
          </div>
        )
      }
    },
    {
      id: 4,
      name: "Métodos de pago",
      route: "/perfil/payment-methods",
      render: () => (
        <div className="w-full md:w-1/3">
          <PaymentMethods/>
        </div>
      )
    },
    {
      id: 5,
      name: "Cerrar Sesión",
      route: "/perfil/logout",
      render: () => (
        <div className="w-full md:w-1/3">
          <Logout history={this.props.history}/>
        </div>
      )
    }
  ];

  userId = getUserInfo().userId;

  fetchUserData() {
    this.accountRequest = request(`users/${this.userId}`);
    this.accountRequest.promise.then(data => this.setState({ ...data }));
  }

  fetchActualSubscription() {
    this.actualSubscriptionRequest = request(`users/${this.userId}/subscription`);
    this.actualSubscriptionRequest.promise.then(data => {
      this.setState({ subscriptionList: data.subscriptionList, subActive: data.active, nextPayment: data.nextPayment })
    });
  }

  onCouponRedeemed = () => {
    this.setState({ couponRedeemed: true });
  }

  componentDidMount() {
    this.fetchUserData();
    this.fetchActualSubscription();
  }

  componentWillUnmount() {
    if (this.accountRequest) this.accountRequest.cancel();
    if (this.updateAccountRequest) this.updateAccountRequest.cancel();
    if (this.actualSubscriptionRequest) this.actualSubscriptionRequest.cancel();
  }

  handleFormSubmit = ({ values, form }) => {
    this.updateAccountRequest = request(this.getUrl(form), {
      method: "PUT",
      body: JSON.stringify(values)
    });
  };

  goToSubscription = () => {
    this.props.history.push('/nueva-suscripcion');
  }

  cancelSubscription = (subscriptionId) => {
    this.cancelSubscriptionRequest = request(`subscriptions/users`, {
      method: 'DELETE',
      body: JSON.stringify({
        subscriptionId 
      })
    });

    this.cancelSubscriptionRequest.promise.then(response => {
      if (!response.error) {
        this.fetchActualSubscription();
      }
    })
  }

  getUrl = form =>
    form === "personal-information"
      ? `users/${this.userId}`
      : form === "change-password"
      ? `users/${this.userId}/reset-password`
      : "";

  handleSidebarItemClick = (event, route) => {
    if (event.preventDefault) event.preventDefault();
    this.props.history.push(route);
  };

  render() {
    const currentPage = this.props.location.pathname;

    return (
      <Page
        containerStyles={{
          margin: "50px auto",
          width: "100%",
          maxWidth: "80rem"
        }}
      >
        <h1 style={{ textAlign: "center", marginBottom: 50 }}>Mi Cuenta</h1>
        <AccountContainer className="flex flex-col md:flex-row">
          <MediaQuery query="(min-width: 768px)">
            <Sidebar className="list-reset w-1/3">
              {this.pages.map(({ name, route }, index) => {
                return (
                  <Link
                    key={index}
                    href={route}
                    onClick={event => this.handleSidebarItemClick(event, route)}
                    className={`${currentPage === route ? "active" : ""}`}
                  >
                    <SidebarItem>{name}</SidebarItem>
                  </Link>
                );
              })}
            </Sidebar>
          </MediaQuery>
          <MediaQuery query="(max-width: 768px)">
            <div className="relative mb-6">
              <select
                onChange={event =>
                  this.handleSidebarItemClick(
                    event,
                    this.pages.filter(({ id }) => {
                      return id.toString() === event.target.value.toString()
                    })[0].route
                  )
                }
                className="block appearance-none w-full bg-grey-lighter border border-grey-lighter text-grey-darker py-3 px-4 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-grey"
              >
                {this.pages.map(({ id, name }) => {
                  return (
                    <option key={id} value={id}>
                      {name}
                    </option>
                  );
                })}
              </select>
              <div className="pointer-events-none absolute pin-y pin-r flex items-center px-2 text-grey-darker">
                <svg
                  className="fill-current h-4 w-4"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
                </svg>
              </div>
            </div>
          </MediaQuery>
          {this.pages.map(({ route, render }, index) => (
            <Route key={index} exact path={route} render={render}/>
          ))}
        </AccountContainer>
      </Page>
    );
  }
}

export default Profile;
