import React from "react";

const FormField = ({
  type = "text",
  title = "",
  name = "",
  value = "",
  error = false,
  errorMessage = ""
}) => {  
  return (
    <div className="mb-6">
      <label
        className="block text-grey-darker text-sm font-bold mb-2"
        htmlFor="username"
      >
        {title}
      </label>
      <input
        type={type}
        name={name}
        defaultValue={value}
        className="shadow appearance-none border rounded w-full 
      py-2 px-3 text-grey-darker leading-tight focus:outline-none focus:shadow-outline"
      />
      {error ? (
        <div className="text-red mt-1" role="alert">
          {errorMessage}
        </div>
      ) : null}
    </div>
  );
};

export default FormField;
