export function addFutureDates(baseDates) {
  const tomorrow = getDaysFromNowDate(1);
  const twoDaysFromNow = getDaysFromNowDate(2);
  const threeDaysFromNow = getDaysFromNowDate(3);
  const fourDaysFromNow = getDaysFromNowDate(4);

  return [
    ...baseDates,
    { name: "Mañana", value: tomorrow },
    { name: getDayOfWeek(twoDaysFromNow), value: twoDaysFromNow },
    { name: getDayOfWeek(threeDaysFromNow), value: threeDaysFromNow },
    { name: getDayOfWeek(fourDaysFromNow), value: fourDaysFromNow }
  ];
}

function getDaysFromNowDate(num) {
  return new Date(new Date().setDate(new Date().getDate() + num));
}

function getDayOfWeek(date) {
  const days = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];

  return days[date.getDay()];
}
