import React, { Component } from "react";
import { Page } from "components";
import request from "lib/request";

class VerifyEmail extends Component {
  constructor(props) {
    super(props);

    let verificationToken = null;
    let verifiedToken = null;
    let params = new URLSearchParams(this.props.location.search);

    if (params.has("verificationToken")) {
      verificationToken = params.get("verificationToken");
    } else {
      verifiedToken = false;
    }

    this.state = {
      verificationToken,
      verifiedToken
    };
  }

  componentDidMount() {
    if (this.state.verificationToken && this.state.verifiedToken === null) {
      setTimeout(() => {
        this.verificationRequest = request(
          `verify-email?verificationToken=${this.state.verificationToken}`
        );
        this.verificationRequest.promise.then(response => {
          if (!response.error) {
            this.setState({
              verifiedToken: true
            });
            setTimeout(() => {
              this.props.history.push("login");
            }, 5000);
          } else {
            switch (response.error.status) {
              case 401:
                this.setState({
                  verifiedToken: false
                });
                break;
              case 400:
                this.setState({
                  message:
                    response.body.message === "User already verified"
                      ? "Esta cuenta ya ha sido verificada"
                      : "Ha habido algún error",
                  verifiedToken: true
                });
                break;
              default:
                this.setState({
                  verifiedToken: false
                });
            }
          }
        });
      }, 1500);
    }
  }

  render() {
    const { verifiedToken, message } = this.state;
    return (
      <Page
        showMenus={false}
        showProfile={false}
        className="page-container public-page verify-email-page"
      >
        <div className="page-content">
          {verifiedToken === false && (
            <p>
              No hemos encontrado ningún token de validación. Por favor, accede
              a esta página desde el email que te hemos envíado para verificar
              tu cuenta
            </p>
          )}
          {verifiedToken === true && message && <p>{message}</p>}
          {verifiedToken === true && !message && (
            <p>
              Email verificado. En 5s se te reconducirá a la página de login
            </p>
          )}
        </div>
      </Page>
    );
  }
}

export default VerifyEmail;
