import React, { useEffect, useState } from "react";
import request from "../lib/request";
import { PaymentForm } from "./index";

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { NavLink } from "react-router-dom";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
//   stripeAccount: localStorage.getItem("stripeKey"),
// });

export default ({ select = {}, setSelect = () => null, newCard = true }) => {
  const [paymentMethods, setPaymentMethods] = useState(null);
  const stripeKey = localStorage.getItem("stripeKey");
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY, {
    stripeAccount: stripeKey,
  });

  useEffect(() => {
    refreshMethods();
  }, []);

  useEffect(() => {
    if (paymentMethods && (!select || !select.cardId)) {
      setSelect(paymentMethods[0]);
    }
  }, [paymentMethods]);

  const refreshMethods = () => {
    const requestMethods = request(`subscriptions/users/cards`);
    requestMethods.promise.then(data => {
      setPaymentMethods(data.cards || [])
    }).catch(() => {
      setPaymentMethods([])
    });
  };

  const saveCard = (pm) => {
    if (pm) {
      const requestMethods = request(`subscriptions/users/cards`, {
        method: "POST",
        redirectOnUnauthorized: true,
        body: JSON.stringify({
          paymentMethodId: pm.id
        })
      });
      requestMethods.promise.then(data => {
        setPaymentMethods(data.cards)
      });
    }
  };

  return (
    <div className="container">
      {!paymentMethods && <div className="row">
        Cargando.....
      </div>}
      {(Array.isArray(paymentMethods) && paymentMethods.length <= 0) && <div className="row">
        No dispone de ningún método de pago. A ser posible podrá introducir uno a continuación o en la sección <NavLink
        to={"/perfil/payment-methods"}>
        métodos de pago
      </NavLink>
      </div>}
      <div className="row mb-5">
        <div className="col">
          {(paymentMethods || []).map((method, key) =>
            <div onClick={() => setSelect(method)} key={key} className="py-2 w-1" style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              borderBottom: "1px solid white",
              cursor: "pointer"
            }}>
              <div style={{ display: "flex" }}>
                <div className="mr-3">
                  {select.cardId === method.cardId && "✔"}
                </div>
                XXXX XXXX XXXX {method.last4}
              </div>
              <div>
                {method.expiration}
              </div>
            </div>
          )}
        </div>
      </div>
      {(newCard && stripeKey) &&
      <React.Fragment>
        <div className="row py-5">
          Introducir nuevo método de pago
        </div>
        <div className="row">
          <Elements stripe={stripePromise}>
            <PaymentForm onPaymentMethodCreate={saveCard}/>
          </Elements>
        </div>
      </React.Fragment>}
    </div>
  );
};

