import React from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import {
  NavBar,
  Footer,
  Slider,
  PlayItEverywhere,
  ReasonsToSubscribe,
  WhatIsIberaliaGo
} from "./";
import { navbarHeight } from "./responsive";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Container = styled.div`
  margin-top: 77px;

  @media (max-width: 900px) {
    margin-top: ${navbarHeight}px;
  }
`;

const LoginMobileLink = styled.a`
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;

  a {
    margin-left: 5px;
    color: white;
    font-weight: 600;
  }
`;

const StyledNavBar = styled(NavBar)`
  font-size: 12pt;
  font-weight: 400;
`;

const ListItem = styled.li`
  a {
    text-decoration: none;
    color: white;
    font-weight: 600;
    border: 1px solid white;
    border-radius: 50px;
    padding: 6px 20px 6px 20px;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: #0c9ec7;
      border-color: #0c9ec7;
    }
  }
`;

const Modal = styled.div`
  top: 0px;
  left: 0px;
  overflow: hidden;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: rgb(0, 0, 0);
  z-index: 99999;
`;

const ResponsiveIframe = styled.iframe`
  width: 80%;
  height: 80%;

  @media (max-width: 1200px) {
    height: 50%;
  }

  @media (max-width: 768px) {
    height: 25%;
  }
`;

const headerSlides = [
  {
    title: "DISFRUTA DE LOS MEJORES DOCUMENTALES DE CAZA,",
    renderTitle: () => (
      <span style={{ display: "block" }}>ESTÉS DONDE ESTÉS</span>
    ),
    subtitle: "Disfrútalos sólo en IberaliaGo",
    image: require("./images/foto-arriba-1.jpg")
  },
  {
    title: "DISFRUTA DE LOS MEJORES DOCUMENTALES DE PESCA,",
    renderTitle: () => (
      <span style={{ display: "block" }}>ESTÉS DONDE ESTÉS</span>
    ),
    subtitle: "Sólo en IberaliaGo",
    image: require("./images/foto-arriba-2.jpg")
  }
];

const giveItATrySlides = [
  {
    title: "Las mejores monterías",
    subtitle: "Todas las temporadas",
    image: require("./images/monterias.jpg")
  },
  {
    title: "Recechos increíbles",
    subtitle: "Todas las temporadas",
    image: require("./images/recechos.jpg")
  },
  {
    title: "Pesca de mar",
    subtitle: "Todas las temporadas",
    image: require("./images/pesca-mar.jpg")
  },
  {
    title: "Pesca de rio",
    subtitle: "Todas las temporadas",
    image: require("./images/pesca-rio.jpg")
  }
];

class Home extends React.Component {
  state = {
    isModalOpened: false
  };

  toggleModal = () => {
    this.setState({ isModalOpened: !this.state.isModalOpened });
  };

  onKeyDown = ({ keyCode }) => {
    return keyCode === 27 && this.toggleModal();
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyDown, false);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);
  }

  render() {
    const { isModalOpened } = this.state;

    return (
      <React.Fragment>
        {isModalOpened && (
          <Modal>
            <div style={{ width: "80%" }}>
              <FontAwesomeIcon
                icon="times"
                size="3x"
                onClick={this.toggleModal}
                style={{
                  marginBottom: 10,
                  cursor: "pointer",
                  float: "right"
                }}
              />
            </div>
            <ResponsiveIframe
              src="https://www.youtube.com/embed/0LqGzqoYevk?autoplay=1"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </Modal>
        )}

        <React.Fragment>
          <StyledNavBar
            showProfile={false}
            menus={[
              {
                title: "Sobre Nosotros".toUpperCase(),
                url: "/quienes-somos"
              },
              {
                title: "Contacto".toUpperCase(),
                url: "/contacto"
              }
            ]}
            rightButtons={() => (
              <React.Fragment>
                <ListItem>
                  <NavLink to="/registro">CREA TU CUENTA</NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to="/login">INICIAR SESIÓN</NavLink>
                </ListItem>
              </React.Fragment>
            )}
          />
          <Container>
            <MediaQuery query={`(max-width: 900px)`}>
              <LoginMobileLink>
                ¿Ya tienes cuenta?
                <a href="/login">INICIAR SESIÓN</a>
              </LoginMobileLink>
            </MediaQuery>
            <Slider slides={headerSlides} />
            <WhatIsIberaliaGo />
            <ReasonsToSubscribe openModal={this.toggleModal} />
            <PlayItEverywhere />
            <Slider slides={giveItATrySlides} height={900} paddingTop={400} />
          </Container>
          <Footer />
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default Home;
