import React from "react";
import { render } from "react-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import {
  faPlay,
  faTimes,
  faArrowLeft,
  faChevronLeft,
  faChevronRight,
  faArrowCircleLeft,
  faArrowCircleRight
} from "@fortawesome/free-solid-svg-icons";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

library.add(
  fab,
  faPlay,
  faTimes,
  faArrowLeft,
  faTimes,
  faChevronLeft,
  faChevronRight,
  faArrowCircleLeft,
  faArrowCircleRight
);
render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
